import {ref} from "vue";

/**
 * @brief Vytvoří objekt alertu s členskými funkcemi a dodatečnými parametry
 * @param alertState Objekt s parametry alertu: objekt musí obsahovat **type**, **title** a **message**
 * @returns Objekt alertu, který je pak vložen do pole alertStore
 */
export function useAlert (alertState) {
    // Alert typy: waiting, success, warning, danger
    const type = ref(alertState.type);
    const title = ref(alertState.title);
    const message = ref(alertState.message);
    const createdAt = new Date().toTimeString().split(" ")[0];
    // const isVisible = ref(true);
    // const fade = ref('');
    const data = ref(alertState.data);
    const areDataShown = ref(false);
    const options = ref({
        textTrusted: alertState.options?.textTrusted ?? false,
        titleTrusted: alertState.options?.titleTrusted ?? false,
    });
    // const alertDuration = ref(alertState.duration);

    // function closeAlert() {
    //     fade.value = 'fade';
    //
    //     setTimeout(() => {
    //         isVisible.value = false;
    //         }, 500);
    //
    //
    // }
    //
    // function hideAlert(duration = 5000, isHistory = false) {
    //     if(!isHistory) {
    //         fade.value = 'startFade';
    //         timer =
    //             setTimeout(() => {
    //                 closeAlert();
    //             }, duration)
    //     } else if(isHistory && !isVisible.value) {
    //         preventClosing()
    //         fade.value = '';
    //     }
    // }
    //
    // function preventClosing () {
    //     if(timer) {
    //         fade.value = 'show';
    //         clearTimeout(timer);
    //     }
    // }

    function changeToSuccess(header, text, jsonData) {
        changeAlertState('success', header, text, jsonData);
    }

    function changeToInfo(header, text, jsonData) {
        changeAlertState('info', header, text, jsonData);
    }

    function changeToWarning(header, text, jsonData) {
        changeAlertState('warning', header, text, jsonData);
    }

    function changeToError(header, text, jsonData) {
        changeAlertState('danger', header, text, jsonData);
    }

    function changeAlertState(typ, header, text, jsonData = null) {
        type.value = typ;
        title.value = header ? header : title.value;
        message.value = text ? text : message.value;
        if(jsonData) {
            data.value = jsonData;
        }
    }

    function toggleData() {
        areDataShown.value = !areDataShown.value;
    }

    return { type, title, message, createdAt, data, areDataShown, toggleData, changeToSuccess, changeToWarning, changeToInfo, changeToError, options };
}
<script setup>
    import {IconProps, useIcon} from './useIcon.js'

    const props = defineProps(IconProps(22,22));

    const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 22 22" fill="none" :style="{color: color}">
        <path d="M19.25 17.4167H2.74998C2.50686 17.4167 2.27371 17.5132 2.1018 17.6852C1.92989 17.8571 1.83331 18.0902 1.83331 18.3333C1.83331 18.5764 1.92989 18.8096 2.1018 18.9815C2.27371 19.1534 2.50686 19.25 2.74998 19.25H19.25C19.4931 19.25 19.7263 19.1534 19.8982 18.9815C20.0701 18.8096 20.1666 18.5764 20.1666 18.3333C20.1666 18.0902 20.0701 17.8571 19.8982 17.6852C19.7263 17.5132 19.4931 17.4167 19.25 17.4167Z" fill="currentColor"/>
        <path d="M11 1.83333C10.7569 1.83333 10.5238 1.92991 10.3518 2.10182C10.1799 2.27373 10.0834 2.50688 10.0834 2.75V12.4575L7.06753 9.4325C6.89492 9.25989 6.6608 9.16292 6.41669 9.16292C6.17258 9.16292 5.93847 9.25989 5.76586 9.4325C5.59325 9.60511 5.49628 9.83922 5.49628 10.0833C5.49628 10.3274 5.59325 10.5616 5.76586 10.7342L10.3492 15.3175C10.4344 15.4034 10.5358 15.4716 10.6475 15.5182C10.7592 15.5647 10.879 15.5886 11 15.5886C11.121 15.5886 11.2409 15.5647 11.3526 15.5182C11.4643 15.4716 11.5656 15.4034 11.6509 15.3175L16.2342 10.7342C16.3197 10.6487 16.3875 10.5472 16.4337 10.4356C16.48 10.3239 16.5038 10.2042 16.5038 10.0833C16.5038 9.96246 16.48 9.84277 16.4337 9.7311C16.3875 9.61943 16.3197 9.51797 16.2342 9.4325C16.1487 9.34703 16.0473 9.27923 15.9356 9.23298C15.8239 9.18672 15.7042 9.16292 15.5834 9.16292C15.4625 9.16292 15.3428 9.18672 15.2311 9.23298C15.1195 9.27923 15.018 9.34703 14.9325 9.4325L11.9167 12.4575V2.75C11.9167 2.50688 11.8201 2.27373 11.6482 2.10182C11.4763 1.92991 11.2431 1.83333 11 1.83333Z" fill="currentColor"/>
    </svg>
</template>

<style scoped>

</style>
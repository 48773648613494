<script setup>
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import Thead from "@/Components/Table/Thead.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Table from "@/Components/Table/Table.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import Button from "@/Components/Inputs/Button.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import {ref, watch} from "vue";
import InputField from "@/Components/Inputs/InputField.vue";
import TinyMCE from "@/Components/Inputs/TinyMCE.vue";
import TextArea from "@/Components/Inputs/TextArea.vue";
import Groups from "../Edit/Groups.vue";
import TranslationRow from "./TranslationRow.vue";

const props = defineProps({
    modelValue: Array,
    languageID: Number,
})

const emit = defineEmits(['update:modelValue']);
const translationItemGroups = ref(props.modelValue);
const languageID = ref(props.languageID);

watch(props.modelValue, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(translationItemGroups.value)) {
        translationItemGroups.value = newVal;
    }
});

watch(translationItemGroups, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(props.modelValue)) {
        emit('update:modelValue', newVal);
    }
});

const itemsRefs = ref([]);
const validateAllTranslations = () => {
    return itemsRefs.value.every(ref => ref.isTranslationValid());
};

defineExpose({ validateAllTranslations });
</script>

<template>
    <div>
        <Table v-for="(fieldGroup, groupIndex) in translationItemGroups" :key="groupIndex" class="mb-3 translation-group-wrapper">
            <template v-if="!fieldGroup.ToBeDeleted" >
                <Thead>
                <TheadTr class="translate-group-name-wrapper">
                    <TheadTh :colspan="3">
                        <h2 class="my-2">{{ fieldGroup.Type }}</h2>
                    </TheadTh>
                    <TheadTh :colspan="2" class="text-right">
<!--                        <div class="text-success text-xl"><i class="fa fa-check"></i></div>-->
                    </TheadTh>
                </TheadTr>
                <TheadTr>
                    <TheadTh  class="w-[33%]">
                        Zdrojový text
                    </TheadTh>
                    <TheadTh >
                        Překlad
                    </TheadTh>
                    <TheadTh class="w-[140px]">
                        Počet znaků
                    </TheadTh>
                    <TheadTh class="w-[150px]">
                        Stav
                    </TheadTh>
                </TheadTr>
                </Thead>
                <Tbody>
                <TbodyTr v-for="(item, index) in fieldGroup.translationItems" :key="index">
                    <TranslationRow ref="itemsRefs" v-model="fieldGroup.translationItems[index]" :languageID="languageID"></TranslationRow>
                </TbodyTr>
                </Tbody>
            </template>
        </Table>
    </div>
</template>

<style scoped>

</style>
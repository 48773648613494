<script setup>
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import Thead from "@/Components/Table/Thead.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Table from "@/Components/Table/Table.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import Button from "@/Components/Inputs/Button.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import {ref, watch} from "vue";
import InputField from "@/Components/Inputs/InputField.vue";
import TinyMCE from "@/Components/Inputs/TinyMCE.vue";
import TextArea from "@/Components/Inputs/TextArea.vue";
import axios from "axios";

const STATUS_NONE = 'none';
const STATUS_LOADING = 'loading';
const STATUS_SAVED = 'saved';
const STATUS_ERROR = 'error';
const STATUS_VALIDATION_ERROR = 'validation_error';

const props = defineProps({
    modelValue: Array,
    languageID: Number,
})

const emit = defineEmits(['update:modelValue']);
const translationRow = ref(props.modelValue);
const languageID = ref(props.languageID);
const status = ref(STATUS_NONE);

watch(props.modelValue, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(translationRow.value)) {
        translationRow.value = newVal;
    }
});

watch(translationRow, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(props.modelValue)) {
        emit('update:modelValue', newVal);
    }
});
watch(translationRow.Item, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(props.modelValue)) {
        emit('update:modelValue', newVal);
    }
});


const isTranslationValid = () => {
    if(translationRow.value.MaxLenght == null){
        return true;
    }
    if (translationRow.value.translationItemLangs.Translation === '') {
        // status.value = STATUS_VALIDATION_ERROR;
        return false;
    }
    if(translationRow.value.translationItemLangs.Translation.length > parseInt(translationRow.value.MaxLenght)){
        status.value = STATUS_VALIDATION_ERROR;
        return false;
    }
    return true;
};
const handleTranslationChange = (event) => {
    let value;
    if(typeof event === 'string'){
        value = event;
    }else{
        value = event.target.value;
    }
    let translationItemID = translationRow.value.ID;
    var bodyFormData = new FormData();
    bodyFormData.append('translationItemId', translationItemID);
    bodyFormData.append('value', value);
    bodyFormData.append('languageId', languageID.value);

    if(translationRow.value.translationItemLangs.Translation !== '' && !isTranslationValid()){
        status.value = STATUS_VALIDATION_ERROR;
        return false;
    }
    status.value = STATUS_LOADING;
    axios.post('/default/translation-tool/update-translation', bodyFormData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => {
        if (response.data.success) {
            status.value = STATUS_SAVED;
            // window.location.href = '/default/translation-tool/index';
        } else {
            alert('Došlo k chybě při odesílání dat: ' + response.data.message);
            status.value = STATUS_ERROR;
        }
    })
    .catch(error => {
        status.value = STATUS_ERROR;
        console.error('Error submitting data:', error);
        alert('Nastala chyba při ukládání překladu');
    });
};
defineExpose({ isTranslationValid });
</script>

<template>
    <TbodyTd class="original-text" v-html="translationRow.Item">
    </TbodyTd>
    <TbodyTd class="translated-text">
        <InputField  @change="handleTranslationChange($event)" size="small" v-if="translationRow.Type === 'input'" v-model="translationRow.translationItemLangs.Translation" />
        <TextArea  @change="handleTranslationChange($event)" v-else-if="translationRow.Type === 'textarea'" v-model="translationRow.translationItemLangs.Translation"></TextArea>
        <TinyMCE  @change="handleTranslationChange($event)" v-else-if="translationRow.Type === 'wysiwyg'" v-model="translationRow.translationItemLangs.Translation"></TinyMCE>
        <div v-if="translationRow.Note !== null && translationRow.Note !== ''" class="bg-blue-100 w-full rounded-b-lg pl-2 pt-1 pb-1" v-html="translationRow.Note.replace(/\n/g, '<br>')">

        </div>
    </TbodyTd>
    <TbodyTd class="max-chars">
        <div :class="{'text-danger': !isTranslationValid()}">
            <span v-if="translationRow.MaxLenght !== '0' && translationRow.MaxLenght !== '' && translationRow.MaxLenght != null">
            {{ translationRow.translationItemLangs.Translation.length }}/{{ parseInt(translationRow.MaxLenght) }}
            </span>
            <span v-else>
            {{ translationRow.translationItemLangs.Translation.length }}
            </span>
        </div>
    </TbodyTd>
    <TbodyTd class="status">
        <div v-if="status === STATUS_ERROR" class="text-danger"><i class="fa fa-times"></i> Nastala chyba při ukládání </div>
        <div v-if="status === STATUS_VALIDATION_ERROR" class="text-danger"><i class="fa fa-times"></i> Opravte chybu pro uložení </div>
        <div v-else-if="status === STATUS_LOADING" class=""><i class="fa fa-refresh fa-spin"></i> Ukládání </div>
        <div v-else-if="status === STATUS_SAVED" class="text-success"><i class="fa fa-check"></i> Uloženo</div>
    </TbodyTd>
</template>

<style scoped>
    .original-text :deep(p){
        margin-bottom: 0.5rem;
    }
</style>
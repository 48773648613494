<script setup lang="ts">

import Button from "@/Components/Inputs/Button.vue";
import InputField from "@/Components/Inputs/InputField.vue";
import { reactive, ref } from "vue";
import { SheetDefinitionColumn } from "@project/Excel/Handsontable/js/SheetDefinitionColumn";
import Modal from "@/Components/Modal/Modal.vue";
import { cloneDeep } from "lodash";
import Table from "@/Components/Table/Table.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Thead from "@/Components/Table/Thead.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";

const props = defineProps<{
    column?: SheetDefinitionColumn|null,
    show: boolean,
}>();

const emit = defineEmits(['close', 'save']);

const column = reactive(props.column ? cloneDeep(new SheetDefinitionColumn(props.column)) : new SheetDefinitionColumn());

const show = ref<boolean>(props.show);

const save = () => {
    emit('save',column);
    close();
}

const close = () => {
    show.value = false;
    emit('close', column);
}

</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close">
        <template #header>
            <span v-if="props.column">Úprava sloupce</span>
            <span v-else>Vytvoření sloupce</span>
        </template>
        <template #body>
            <div class="body">
                <InputField
                    v-model="column.title"
                    label="Název"
                    class="input"
                />
                <InputField
                    v-model="column.alias"
                    label="Data alias"
                    class="input"
                />
                <InputField
                    v-model="column.type"
                    label="Typ sloupce"
                    class="input"
                />
                <InputField
                    v-model="column.defaultValue"
                    label="Defaultní hodnota"
                    class="input"
                />
                <InputField
                    v-model="column.formating"
                    label="Formátování"
                    class="input"
                />
                <InputField
                    v-model="column.width"
                    label="Šířka sloupce"
                    class="input"
                />
            </div>
        </template>
        <template #footer>
            <Button variant="green" @click="save">Uložit</Button>
            <Button variant="red" @click="close">Zavřít</Button>
        </template>
    </Modal>
</template>

<style scoped>
    .body{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 16px;

        .input{
            width: calc(50% - 20px);
        }
    }
</style>
import {computed, h, onMounted, reactive, ref, watch, defineComponent, markRaw, watchEffect} from 'vue';

export function createRow({ row, key, cells}) {
    const props = reactive({
        row,
        key,
        cells,
    });

    const isLoaded = computed(() => props.row.isLoaded);
    const rowData = computed(() => props.row.data());
    const isSelected = computed(() => props.row.isSelected());

    const renderedProps = reactive({
        key: String(props.key),
        style: {
            height: props.row.dataGrid.settings.row.height + 'px',
            maxHeight: props.row.dataGrid.settings.row.height + 'px',
            minHeight: props.row.dataGrid.settings.row.height + 'px',
        },
        class: {
            odd: props.row.position % 2 === 0,
            even: props.row.position % 2 === 1,
            selected: false,
            dataGridRow: true,
        },
        onClick: (event) => onClick(event),
        onDblclick: (event) => onDblclick(event),
        onContextmenu: (event) => onRightClick(event),
    });

    watchEffect(() => {
        renderedProps.class.selected = isSelected.value;
    });

    const onClick = (event) => {
        if(!isLoaded.value){
            return;
        }

        try{
            if(props.row.dataGrid.settings.row.onBeforeClick?.(event, props.row) === false){
                return;
            }
        }catch (e){
            console.error(e);
        }

        props.row.toggleSelect(event);

        try{
            props.row.dataGrid.settings.row.onClick?.(event, props.row);
        }catch (e){
            console.error(e);
        }
    }

    const onDblclick = (event) => {
        if(!isLoaded.value){
            return;
        }

        try{
            if(props.row.dataGrid.settings.row.onBeforeDblclick?.(event, props.row) === false){
                return;
            }
        }catch (e){
            console.error(e);
        }


        try{
            props.row.dataGrid.settings.row.onDblclick?.(event, props.row);
        }catch (e){
            console.error(e);
        }
    }

    const onRightClick = (event) => {
        event.preventDefault();

        props.row.select(true, event);

        if(props.row.dataGrid.contextMenu){
            props.row.dataGrid.contextMenu.position = {
                x: event.pageX,
                y: event.pageY,
            };

            props.row.dataGrid.contextMenu.show(true);
        }
    }

    const rowComponent = markRaw(defineComponent({
        setup() {
            const rowRef = ref(null);

            const executeRowCallback = () => {
                const { rowCallback } = props.row.dataGrid.settings.row;
                if (typeof rowCallback === 'function' && isLoaded.value) {
                    try{
                        rowCallback(rowRef.value, rowData.value, renderedProps);
                    }catch (e){
                        console.error(e);
                    }
                }
            };

            watch([rowRef, isLoaded, rowData], executeRowCallback, { deep: true });

            return { rowRef };
        },
        render() {
            return h('div', { ...renderedProps, ref: 'rowRef' }, props.cells);
        }
    }));

    return {
        props,
        rowComponent,
        renderedProps,
    };
}

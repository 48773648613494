<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(19,19));

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 19 19" fill="none" :style="{color: color}">
        <rect width="19" height="19" rx="3" fill="currentColor"/>
        <path d="M3.64635 6.9183C3.13159 6.9183 2.71429 7.3356 2.71429 7.85035C2.71429 8.36511 3.13159 8.78241 3.64635 8.78241H14.7694H15.3537C15.8684 8.78241 16.2857 8.36511 16.2857 7.85035C16.2857 7.3356 15.8684 6.9183 15.3537 6.9183H14.7694H3.64635Z" fill="white"/>
        <path d="M3.64635 10.2176C3.13159 10.2176 2.71429 10.6349 2.71429 11.1496C2.71429 11.6644 3.13159 12.0817 3.64635 12.0817H14.7694H15.3537C15.8684 12.0817 16.2857 11.6644 16.2857 11.1496C16.2857 10.6349 15.8684 10.2176 15.3537 10.2176H14.7694H3.64635Z" fill="white"/>
        <path d="M3.64635 13.5168C3.13159 13.5168 2.71429 13.9341 2.71429 14.4489C2.71429 14.9637 3.13159 15.3809 3.64635 15.3809H14.7694H15.3537C15.8684 15.3809 16.2857 14.9637 16.2857 14.4489C16.2857 13.9341 15.8684 13.5168 15.3537 13.5168H14.7694H3.64635Z" fill="white"/>
        <path d="M3.64635 3.61905C3.13159 3.61905 2.71429 4.03634 2.71429 4.5511C2.71429 5.06586 3.13159 5.48316 3.64635 5.48316H14.7694H15.3537C15.8684 5.48316 16.2857 5.06586 16.2857 4.5511C16.2857 4.03634 15.8684 3.61905 15.3537 3.61905H14.7694H3.64635Z" fill="white"/>
    </svg>
</template>

<style scoped>

</style>
<script setup>

import Modal from "@/Components/Modal/Modal.vue";
import Button from "@/Components/Inputs/Button.vue";
import { reactive, ref, watch } from "vue";
import { useRouter } from 'vue-router'

const props = defineProps({
    show: Boolean,
    table: Object,
});

const router = useRouter();

const emit = defineEmits(['close']);

const show = ref(props.show);

const backUnsaved = () => {
    router.push({ name: 'homepage' });
}

const backSaved = async () => {
    const result = await table.saveSheetDataToServer();
    if(result){
        router.push({ name: 'homepage' });
    }
}

const close = () => {
    show.value = false;
    emit('close');
}

watch(() => props.show, (val) => {
    show.value = props.show;
});
</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close">
        <template #header>
            Opustit bez uložení?
        </template>
        <template #body>
            <p>Chcete opustit stránku bez uložení změn?</p>
        </template>
        <template #footer>
            <Button @click="backSaved" variant="white" :outline="true" icon="send" icon-color="green" icon-direction="left" tooltip="Uloží data z tabulky do databáze">Uložit</Button>
            <Button variant="default" @click="backUnsaved">Odejít bez uložení</Button>
            <Button variant="red" @click="close">Zavřít</Button>
        </template>
    </Modal>
</template>

<style scoped>

</style>
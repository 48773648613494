<script setup>
import { onMounted, onUnmounted } from 'vue';
import ContextMenuItem from './ContextMenuItem.vue';

const props = defineProps({
    contextMenu: {
        type: Object,
        required: true
    }
});

const handleClickAnywhere = () => {
    props.contextMenu.show(false);
}

onMounted(() => {
    document.addEventListener('click', handleClickAnywhere);
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickAnywhere);
});

</script>

<template>
    <Teleport to="body">
        <div
            class="dataTable-context"
            @click="handleClickAnywhere"
            :style="{left: contextMenu.position.x + 'px', top: contextMenu.position.y + 'px'}">
            <ul class="items">
                <ContextMenuItem v-for="(item, index) in contextMenu.items" :key="index" :item="item" />
            </ul>
        </div>
    </Teleport>
</template>

<style scoped>
.dataTable-context {
    position: absolute;
    border: 1px solid #B2B2B2;
    width: 250px;
    background: #F9F9F9;
    box-shadow: 3px 3px 2px rgba(140, 140, 140, 0.15);
    z-index: 555;

    .items {
        list-style: none;
        margin: 0;
        font-size: 17px;
        padding: 0;
    }
}
</style>

import Handsontable from "handsontable";
import {CurrencyEditor} from "@project/Excel/Handsontable/js/ColumnTypes/Currency/CurrencyEditor";

export class PercentageType {
    public editor: any;
    public renderer: any;
    public validator: any;
    public className: string;

    constructor() {
        this.editor = CurrencyEditor;
        this.renderer = this.customRenderer;
        this.validator = this.customValidator;
        this.className = 'currency-percentage';
    }

    customRenderer(instance, td, row, column, prop, value, cellProperties) {
        if(isNaN(parseFloat(value))){
            td.innerHTML = value;
        }else{
            td.innerHTML = (value * 100).toFixed(2) + ' %';
        }

    }

    customValidator(query, callback){
        callback(true);
    }

}
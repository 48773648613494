import Handsontable from "handsontable";

export class CurrencyEditor extends Handsontable.editors.TextEditor {
    constructor(instance: any) {
        super(instance);
    }

    // Override the setValue method to show the value divided by 100
    setValue(value?: any) {
        // if (value && this.cellProperties.currency && !isNaN(parseFloat(value))) {
        //     value = (value / 100).toFixed(2);
        // }
        super.setValue(value);
    }

    // Override the getValue method to save the value multiplied by 100
    getValue() {
        let value = super.getValue();
        // if (value && this.cellProperties.currency && !isNaN(parseFloat(value))) {
        //     value = parseFloat(value) * 100;
        // }
        return value;
    }
}
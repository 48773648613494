<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(16, 16));

const {color} = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" version="1.1" :width="props.width"
         :height="props.height" :style="{color: color}">
        <path fill="currentColor"
              d="m 22,27 c -1.104569,0 -2,0.895431 -2,2 0,1.104569 0.895431,2 2,2 h 6.835938 l -3.033204,-4 z m 0,10 c -1.104569,0 -2,0.895431 -2,2 0,1.104569 0.895431,2 2,2 h 9.833984 v -4 z"/>
        <path fill="currentColor"
              d="m 4,33 a 2,2 0 0 0 -1.4140625,0.585937 2,2 0 0 0 0,2.828125 l 6,6 a 2.0002,2.0002 0 0 0 2.8281255,0 l 5.999999,-6 a 2,2 0 0 0 0,-2.828125 2,2 0 0 0 -2.828124,0 L 10,38.171875 5.4140625,33.585937 A 2,2 0 0 0 4,33 Z"/>
        <path fill="currentColor"
              d="m 8.5859375,5.5859375 -6,6.0000005 a 2,2 0 0 0 0,2.828125 2,2 0 0 0 2.828125,0 L 10,9.828125 l 4.585938,4.585938 a 2,2 0 0 0 2.828124,0 2,2 0 0 0 0,-2.828125 L 11.414063,5.5859375 a 2.0002,2.0002 0 0 0 -2.8281255,0 z"/>
        <path fill="currentColor"
              d="M 10,5 A 2,2 0 0 0 8,7 v 34 a 2,2 0 0 0 2,2 2,2 0 0 0 2,-2 V 7 A 2,2 0 0 0 10,5 Z"/>
        <path fill="currentColor"
              d="m 22,7 a 2,2 0 0 0 -2,2 2,2 0 0 0 2,2 H 44 A 2,2 0 0 0 46,9 2,2 0 0 0 44,7 Z"/>
        <path fill="currentColor"
              d="m 22,17 a 2,2 0 0 0 -2,2 2,2 0 0 0 2,2 h 14 a 2,2 0 0 0 2,-2 2,2 0 0 0 -2,-2 z"/>
        <path
            d="m 38.164694,43.326338 v -9.182606 l 8.328488,-10.983775 c 0.243141,-0.320456 0.01458,-0.780706 -0.387619,-0.780706 H 26.299728 c -0.402198,0 -0.63076,0.46025 -0.387619,0.780706 l 8.328748,10.983515 v 11.308657 c 0,0.276983 0.302755,0.447494 0.539648,0.303796 l 3.060869,-1.855316 c 0.200448,-0.121571 0.32332,-0.33946 0.32332,-0.574271 z"
            fill="currentColor"
            transform="translate(3, 2)" />
    </svg>
</template>

<style scoped>

</style>
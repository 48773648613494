<script setup lang="ts">
import {onMounted, reactive, ref, watch} from "vue";
    import SheetDefinitionEditor from "@project/Excel/ExcelApp/Components/SheetEditor/SheetDefinitionEditor.vue";
    import {cloneDeep} from "lodash";
    import {Sheet} from "@project/Excel/Handsontable/js/Sheet";
    import type {ISheetDefinition} from "@project/Excel/Handsontable/js/SheetDefinition";
    import type {IModelDefinition} from "@project/Excel/Handsontable/js/ModelDefinition";
    import Button from "@/Components/Inputs/Button.vue";
    import Form from "@/Components/Inputs/Form.vue";
    import InputField from "@/Components/Inputs/InputField.vue";
    import NewSelect from "@/Components/Inputs/NewSelect.vue";
    import Icon from "@/Icons/Icon.vue";
import {SheetSettings} from "@project/Excel/Handsontable/js/SheetSettings";
import {SheetManager} from "@project/Excel/Handsontable/js/SheetManager";
import {useSheetManager} from "@project/Excel/ExcelApp/Hooks/useSheetManager";
import { useRouter } from 'vue-router';
import {useLayoutStore} from "@project/Excel/ExcelApp/Components/Layout/useLayout";
import LabelEditorDialog from "@project/Excel/ExcelApp/Components/LabelEditor/LabelEditorDialog.vue";

    const props = withDefaults(defineProps<{
        sheet?: Sheet|null,
        id?: string|number|null,
        isLoaded?: boolean,
        clone?: boolean,
        showConfigurator: any,
        onSave: any,
    }>(), {
        sheet: null,
        clone: true,
        showConfigurator: null
    });

    const {sheetManager}: SheetManager = useSheetManager();

    const sheet: Sheet = reactive({
        data: !props.sheet ? (props.id ? null : new Sheet({settings: new SheetSettings()})) : (props.clone ? new Sheet(cloneDeep(props.sheet)) : new Sheet(props.sheet)), //TODO pořebuji clone?
    });

    const router = useRouter();
    const layoutStore = useLayoutStore(window.pinia);
    // const labels = ref(sheet.data ? sheet.data.labels.map(item => ({value: item.ID, text: item.Name})) : []);
    const labels = reactive({data: sheet.data && sheet.data.labels ? sheet.data.labels.map(item => ({value: item.ID, text: item.Name})) : []});


    onMounted(async () => {
        layoutStore.setOnBack(() => {
            router.push({ name: 'homepage' });
        });

        if(props.id){
            const loadedSheet = await sheetManager.loadByID(props.id);

            if(loadedSheet){
                if(loadedSheet.labels){
                    labels.data = loadedSheet.labels.map(item => ({value: item.ID, text: item.Name}));
                }

                sheet.data = loadedSheet;
            }
        }


        if(!sheet.data.settings){
            sheet.data.loadSettings();
        }
    })

    const onSubmit = async () => {
        let result;
        if(sheet.data.id === null){
            result = await sheetManager.createOnServer(sheet.data);
        }else{
            result = await sheetManager.updateOnServer(sheet.data);
        }

        if(typeof props.onSave === 'function'){
            props.onSave(sheet.data, result);
        }
    };

    const onDefinitionChange = (mDefinition: IModelDefinition, sDefinition: ISheetDefinition) => {
        sheet.data.settings.sheetDefinition = sDefinition;
        sheet.data.settings.modelDefinition = mDefinition;
    };

    watch(() => labels.data, (newVal) => {
        sheet.data.labels = newVal.map(item => ({ID: item.value, Name: item.text}));
    }, {deep: true});


    const showLabelEditorDialog = ref(false);

</script>

<template>
    <div>
        <div v-if="sheet.data">
            <div class="">
                <div class="tw-flex justify-content-between tw-items-center">
                    <div>
                        <h2 v-if="sheet.data.id === null">Vytvořit nový list</h2>
                        <h2 v-else>Editovat list ID ({{sheet.data.id}})</h2>
                    </div>
                    <div>
                        <Button @click="props.showConfigurator" v-if="sheet.data.type === 'price-list'">Konfigurátor</Button>
                        <Button
                            variant="green"
                            @click="onSubmit"
                        >Uložit</Button>
                    </div>
                </div>
                <div>
                    <InputField
                        v-model="sheet.data.name"
                        label="Název"
                        placeholder="Název listu"
                        variant="fancy"
                        icon="search"
                        size="large-xl"
                        class="tw-mt-4"
                    ></InputField>
                    <div class="tw-flex tw-items-end tw-mt-4">
                        <NewSelect
                            url="/admin/sheet-label/data-list"
                            :multiple="true"
                            placeholder="Štítky"
                            label="Štítky"
                            :return-object="true"
                            v-model="labels.data"
                            :filterable="true"
                        >
                        </NewSelect>
                        <Button
                            variant="green"
                            size="small"
                            icon="plus"
                            class="tw-h-[40px]"
                            @click="showLabelEditorDialog = true"
                        ></Button>
                        <LabelEditorDialog
                            :label="null"
                            v-if="showLabelEditorDialog"
                            :show="showLabelEditorDialog"
                            @close="showLabelEditorDialog = false"
                        >
                        </LabelEditorDialog>
                    </div>
                </div>
            </div>

            <div class="m-auto mt-16">
                <SheetDefinitionEditor v-if="sheet.data?.settings" @change="onDefinitionChange" :sheet-settings="sheet.data.settings"></SheetDefinitionEditor>
                <div v-else>Není sheetsetting</div>
            </div>
        </div>
        <div v-else>
            Není sheet
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(17,17));

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 17 17" fill="none" :style="{color: color}">
        <path d="M14.4034 3.52843L11.2019 0.499574C10.8614 0.177404 10.4157 0 9.94689 0H3.85156C2.84461 0 2.02539 0.819221 2.02539 1.82617V15.1738C2.02539 16.1808 2.84461 17 3.85156 17H13.1484C14.1554 17 14.9746 16.1808 14.9746 15.1738V4.85503C14.9746 4.35535 14.7664 3.87182 14.4034 3.52843ZM13.436 3.98438H10.957C10.8655 3.98438 10.791 3.9099 10.791 3.81836V1.48205L13.436 3.98438ZM13.1484 16.0039H3.85156C3.39386 16.0039 3.02148 15.6315 3.02148 15.1738V1.82617C3.02148 1.36847 3.39386 0.996094 3.85156 0.996094H9.79492V3.81836C9.79492 4.45915 10.3162 4.98047 10.957 4.98047H13.9785V15.1738C13.9785 15.6315 13.6061 16.0039 13.1484 16.0039Z" fill="currentColor"/>
        <path d="M12.0527 6.64062H4.74805C4.47299 6.64062 4.25 6.86362 4.25 7.13867C4.25 7.41373 4.47299 7.63672 4.74805 7.63672H12.0527C12.3278 7.63672 12.5508 7.41373 12.5508 7.13867C12.5508 6.86362 12.3278 6.64062 12.0527 6.64062Z" fill="currentColor"/>
        <path d="M12.0527 9.29688H4.74805C4.47299 9.29688 4.25 9.51987 4.25 9.79492C4.25 10.07 4.47299 10.293 4.74805 10.293H12.0527C12.3278 10.293 12.5508 10.07 12.5508 9.79492C12.5508 9.51987 12.3278 9.29688 12.0527 9.29688Z" fill="currentColor"/>
        <path d="M7.16258 11.9531H4.74805C4.47299 11.9531 4.25 12.1761 4.25 12.4512C4.25 12.7262 4.47299 12.9492 4.74805 12.9492H7.16258C7.43763 12.9492 7.66062 12.7262 7.66062 12.4512C7.66062 12.1761 7.43763 11.9531 7.16258 11.9531Z" fill="currentColor"/>
    </svg>
</template>

<style scoped>

</style>
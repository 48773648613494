<script setup>
    import {ref, watch} from "vue";
    import {defineComponent} from "vue";
    import Modal from "@/Components/Modal/Modal.vue";
    import Button from "@/Components/Inputs/Button.vue"
    import NewSelect from "@/Components/Inputs/NewSelect.vue";
    import InputField from "@/Components/Inputs/InputField.vue";
    import {useAlertStore} from "@/Components/Alerts/stores/alertStore.js";

    const props = defineProps({
      show: Boolean,
      columns: Array,
      dataTable: Object
    });

    //alerts
    const alerts = useAlertStore(window.pinia);
    //end alerts

    const emit = defineEmits(['close', 'save']);

    const show = ref(props.show);

    const tableOneColumnSelected = () => {
        const lastSelectedCells = props.dataTable.lastSelectedCells ?? null;
        if(!lastSelectedCells || lastSelectedCells.length !== 1){
            return false;
        }

        if(lastSelectedCells[0][1] === lastSelectedCells[0][3]){
                return props.dataTable.handsontable.toPhysicalColumn(lastSelectedCells[0][1]);
        }

        return false;
    }

    const toColumn = () => {
        if(props.dataTable){
            if(columnForFormula.value === null){
                alerts.error('Vyberte prosím sloupec pro vložení vzorce');
                return;
            }

            if(operation.value === null || columnInFormula.value === null){
                alerts.error('Vyberte prosím operaci a sloupec pro vytvoření vzorce');
                return;
            }

            if (confirm("Opravdu chcete aplikovat hodnotu nad všemi řádky?")) {
                const col = props.columns[columnForFormula.value];

                const data = props.dataTable.sheet.data.map((row) => {
                    row[col.data ? col.joinData : col.alias] = formula.value;
                    return row;
                });

                props.dataTable.updateData(data);

                save();
            }

        }
    }

    const toFilteredColumn = () => {
        if(props.dataTable){
            if(columnForFormula.value === null){
                alerts.error('Vyberte prosím sloupec pro vložení vzorce');
                return;
            }

            if(operation.value === null || columnInFormula.value === null){
                alerts.error('Vyberte prosím operaci a sloupec pro vytvoření vzorce');
                return;
            }

            const col = props.columns[columnForFormula.value];

            const visualColumnIndex = col.position;
            const totalVisualRows = props.dataTable.handsontable.countRows(); // Získáš počet fyzických řádků

            props.dataTable.handsontable.batch(() => {
                for (let visualRowIndex = 0; visualRowIndex < totalVisualRows; visualRowIndex++) {
                    // Používáme fyzické indexy řádku a sloupce pro nastavení hodnoty do zdrojových dat
                    props.dataTable.handsontable.setDataAtCell(visualRowIndex, visualColumnIndex, formula.value);
                }
            });
        }

        save();
    }

    const save = () => {
        emit('save', formula.value);

        close();
    }

    const close = () => {
      show.value = false;
      emit('close');
    }

    watch(() => props.show, (val) => {
      show.value = props.show;
    });

    const currentColumn = tableOneColumnSelected();
    const columnForFormula = ref(currentColumn ? currentColumn : null);
    const columnInFormula = ref(null);
    const operation = ref(null);

    const operations = [
        {text: 'Zvětšit o %', value: 1},
        {text: 'Zmenšit o %', value: 2},
        {text: 'Hodnota sloupce', value: 3},
    ];

    const value = ref(null);
    const formula = ref(null);

    const createFormula = () => {
      if(operation.value === null || columnInFormula.value === null){
          return;
      }

      const col = props.columns[columnInFormula.value];

      if(operation.value === 1) {
          formula.value = '=COLUMN(' + (col.alias ? col.alias : col.joinData) + ')*' + (1+(value.value/100)) + '';
      }else if(operation.value === 2) {
          formula.value = '=COLUMN(' + (col.alias ? col.alias : col.joinData) + ')*' + (value.value/100) + '';
      }else if(operation.value === 3) {
          formula.value = '=COLUMN(' + (col.alias ? col.alias : col.joinData) + ')';
      }
    };

    watch(() => operation.value, (newVal) => {
        createFormula();
    });

    watch(() => value.value, (newVal) => {
        createFormula();
    });

    watch(() => columnInFormula.value, (newVal) => {
        createFormula();
    });

    const toClipboard = () => {
        navigator.clipboard.writeText(formula.value);
    };
</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close" :size="'extraSmall'">
        <template #header>
            Vytvoření vzorce
        </template>
        <template #body>
            <NewSelect
                v-model="columnForFormula"
                label="Do sloupce"
                :options="props.columns.map((column, index) => {return {text: column.title, value: index}})"
                :filterable="true"
            ></NewSelect>
            <NewSelect
                v-model="operation"
                label="Operace"
                :options="operations"
            ></NewSelect>
            <NewSelect
                v-model="columnInFormula"
                label="Ze sloupce"
                :options="props.columns.map((column, index) => {return {text: column.title, value: index}})"
                :filterable="true"
            ></NewSelect>
            <InputField
                v-model="value"
                label="Hodnota (%)"
                class="input"
                v-if="operation !== 3"
            />
            <div class="tw-flex tw-items-end">
                <InputField
                    v-model="formula"
                    label="Výsledný vzorec"
                    class="input tw-grow"
                />
                <Button variant="green" @click="toClipboard" size="small" icon="copy" icon-color="white"></Button>
            </div>
        </template>
        <template #footer>
            <Button variant="green" @click="toFilteredColumn">Aplikovat do vyfiltrovaného sloupce</Button>
            <Button variant="green" @click="toColumn">Aplikovat do celého sloupce</Button>
            <Button variant="red" @click="close">Zavřít</Button>
        </template>
    </Modal>
</template>

<style scoped>

</style>
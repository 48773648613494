<script setup>

import {onMounted, ref} from "vue";
import axios from "axios";
import Grid from "@/DataGrid/Components/Grid.vue";

const emit = defineEmits(['viewSheet', 'editSheet', 'copySheet', 'deleteSheet']);

const gridSetting = ref(null);

onMounted(() => {
    let url = window.remoteUrl + '/default/handsontable/sheet-grid';
    axios.get(url, {

    }).then((response) => {
        gridSetting.value = response.data;
    });
});

window.viewSheet = () => {
    emit('viewSheet', sheet_datagrid.getCurrentItem());
}

window.editSheet = () => {
    emit('editSheet', sheet_datagrid.getCurrentItem());
}

window.copySheet = () => {
    emit('editSheet', sheet_datagrid.getCurrentItem());
}

window.deleteSheet = () => {
    emit('deleteSheet', sheet_datagrid.getCurrentItem());
}

window.sheetGridLabelsFormat = (data, name, row) => {
    if(!data){
        return '';
    }

    const labels = data.split(';');
    let result = '';

    labels.forEach((label) => {
        if(label){
            const name = label.split('|');
            result += `<span class="label">${name[1]}</span>`;
        }
    })

    return result;
}

window.sheetGridTypeFormat = (data, name, row) => {
    if(!data){
        return '';
    }

    let result = '';

    if(data === 'price-list'){
        result = `<div class="type-badge price-list"><span>ceník</span></div>`;
    }else{
        result = `<div class="type-badge list"><span>list</span></div>`;
    }

    return result;
}

</script>

<template>
    <div>
        <Grid v-if="gridSetting" :settings="gridSetting" :id="'sheet_datagrid'" class="sheetDatagrid"></Grid>
    </div>
</template>

<style scoped>
:global(.sheetDatagrid .label){
    background-color: rgb(36, 197, 134);
    padding: 2px 8px 2px 8px;
    color: #FFF;
    border-radius: 15px;
    font-size: 12px;
    margin-right: 5px;
}

:global(.sheetDatagrid .type-badge){
    display: flex;
    justify-content: center;
}

:global(.sheetDatagrid .type-badge span){
    padding: 2px 8px 2px 8px;
    color: #FFF;
    border-radius: 15px;
    font-size: 12px;
    margin-right: 5px;
    display: inline-block;
}

:global(.sheetDatagrid .type-badge.price-list span){
    background-color: rgb(36, 197, 134);
}

:global(.sheetDatagrid .type-badge.list span){
    background-color: rgb(66, 148, 255);
}
</style>
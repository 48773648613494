<script setup>

</script>

<template>
    <div class="table-wrapper">
        <table>
            <slot></slot>
        </table>
    </div>
</template>

<style scoped>
    .table-wrapper{
        position: relative;
        overflow-x: auto;
        border-radius: 10px;
        box-shadow: 0px 4px 44px 0px rgba(9, 19, 37, 0.10);
    }

    table{
        width: 100%;
        border-collapse: collapse;
        text-align: left;
    }
</style>
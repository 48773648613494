import { createRouter, createWebHistory } from 'vue-router'

import Homepage from './../Homepage.vue';
import Labels from './../Labels.vue';
import SheetEditor from "@project/Excel/ExcelApp/Components/SheetEditor/SheetEditor.vue";
import PriceListConfigurator from "@project/Excel/PriceList/Components/PriceListConfigurator.vue";
import DisplaySheet from "@project/Excel/ExcelApp/Components/DisplaySheet/DisplaySheet.vue";
import {useLayoutStore} from "@project/Excel/ExcelApp/Components/Layout/useLayout.js";

const routes = [
    {
        path: '/default/handsontable',
        name: 'homepage',
        component: Homepage
    },
    {
        path: '/default/handsontable/labels',
        name: 'labels',
        component: Labels
    },
    {
        path: '/default/handsontable/create-sheet-from-grid',
        name: 'create-sheet-from-grid',
        component: SheetEditor,
        props: {
            sheet: window.createSheetFromGridSheet,
            onSave: (sheet, result) => {
                if(result && sheet.id){
                    delete window.createSheetFromGridSheet;
                    router.push({ name: 'homepage' });
                }
            }
        }
    },
    {
        path: '/default/handsontable/edit-sheet',
        name: 'new-sheet',
        component: SheetEditor,
        props: {
            id: null,
            onSave: (sheet, result) => {
                if(result && sheet.id){
                    router.push({ name: 'homepage' });
                }
            }
        }
    },
    {
        path: '/default/handsontable/edit-sheet/:id',
        name: 'edit-sheet',
        component: SheetEditor,
        props: route => ({
            id: route.params.id,
            onSave: (sheet, result) => {
                if(result && sheet.id){
                    router.push({ name: 'homepage' });
                }
            }
        })
        //  dá se použít i meta: { showLogo: true } a pak v beforeEach
        //  if (to.meta.showLogo !== undefined) {
        //    layoutStore.setShowLogo(to.meta.showLogo);
        //  }
    },
    {
        path: '/default/handsontable/view-sheet/:id',
        name: 'display-sheet',
        component: DisplaySheet,
        props: true
    },
    {
        path: '/default/handsontable/edit-price-list',
        name: 'new-price-list',
        component: PriceListConfigurator,
        props: { id: null }
    },
    {
        path: '/default/handsontable/edit-price-list/:id',
        name: 'edit-price-list',
        component: PriceListConfigurator,
        props: route => ({ id: route.params.id, showDefinitionEditor: true })
    },
]


const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const layoutStore = useLayoutStore(window.pinia);
    if (to.name === 'homepage') {
        layoutStore.resetOnBack();
        layoutStore.setShowLogo(false);
    } else {
        layoutStore.setOnBack(() => router.push({ name: 'homepage' }));
        layoutStore.setShowLogo(true);
    }
    next();
});

export default router;


<script setup lang="ts">
    import type {ModelDefinition} from "@project/Excel/Handsontable/js/ModelDefinition";
    import {ref} from "vue";
    import Modal from "@/Components/Modal/Modal.vue";
    import Button from "@/Components/Inputs/Button.vue";
    import SheetEditor from "@project/Excel/ExcelApp/Components/SheetEditor/SheetEditor.vue";
    import ActiveFilters from "@project/Excel/Handsontable/Components/ActiveFilters/ActiveFilters.vue";

    const props = defineProps<{
        modelDefinition: ModelDefinition,
        show: boolean,
    }>();

    const emit = defineEmits(['close']);

    const show = ref<boolean>(props.show);

    const close = () => {
        show.value = false;
        emit('close');
    }
</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close" size="medium">
        <template #header>
           <p>Aktivní filtry</p>
        </template>
        <template #body>
            <ActiveFilters :model-definition="modelDefinition" />
        </template>
        <template #footer>
            <Button variant="red" @click="close">Zavřít</Button>
        </template>
    </Modal>
</template>

<style scoped>

</style>
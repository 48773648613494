<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(15,15));

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 15 15" fill="none" :style="{color: color}">
        <path d="M7.5 14.3274C6.98752 14.3274 6.57227 13.9121 6.57227 13.3997V1.60036C6.57227 1.08788 6.98752 0.672623 7.5 0.672623C8.01248 0.672623 8.42773 1.08788 8.42773 1.60036V13.3997C8.42773 13.9121 8.01248 14.3274 7.5 14.3274Z" fill="currentColor"/>
        <path d="M13.3996 8.42773H1.60034C1.08786 8.42773 0.672607 8.01248 0.672607 7.5C0.672607 6.98752 1.08786 6.57227 1.60034 6.57227H13.3996C13.9121 6.57227 14.3274 6.98752 14.3274 7.5C14.3274 8.01248 13.9121 8.42773 13.3996 8.42773Z" fill="currentColor"/>
    </svg>
</template>

<style scoped>

</style>
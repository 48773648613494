<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(15,15));

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 15 15" fill="none" :style="{color: color}">
        <g clip-path="url(#clip0_12_4063)">
            <path d="M13.2812 15H1.71879C0.770645 15 0 14.2293 0 13.2812V2.9687C0 2.02056 0.770645 1.25003 1.71879 1.25003H13.2812C14.2294 1.25003 15 2.02056 15 2.9687V13.2812C15 14.2293 14.2294 15 13.2812 15ZM1.71879 2.18753C1.28815 2.18753 0.9375 2.53806 0.9375 2.9687V13.2812C0.9375 13.7118 1.28815 14.0625 1.71879 14.0625H13.2812C13.7119 14.0625 14.0625 13.7118 14.0625 13.2812V2.9687C14.0625 2.53806 13.7119 2.18753 13.2812 2.18753H1.71879Z" fill="currentColor"/>
            <path d="M14.5312 5.93753H0.46875C0.209999 5.93753 0 5.72753 0 5.46878C0 5.21003 0.209999 5.00003 0.46875 5.00003H14.5312C14.79 5.00003 15 5.21003 15 5.46878C15 5.72753 14.79 5.93753 14.5312 5.93753Z" fill="currentColor"/>
            <path d="M3.59379 3.75C3.33504 3.75 3.12504 3.54 3.12504 3.28125V0.46875C3.12504 0.209999 3.33504 0 3.59379 0C3.85254 0 4.06254 0.209999 4.06254 0.46875V3.28125C4.06254 3.54 3.85254 3.75 3.59379 3.75Z" fill="currentColor"/>
            <path d="M11.4062 3.75C11.1475 3.75 10.9375 3.54 10.9375 3.28125V0.46875C10.9375 0.209999 11.1475 0 11.4062 0C11.665 0 11.875 0.209999 11.875 0.46875V3.28125C11.875 3.54 11.665 3.75 11.4062 3.75Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_12_4063">
                <rect width="15" height="15" fill="currentColor"/>
            </clipPath>
        </defs>
    </svg>
</template>

<style scoped>

</style>
<script setup>
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import Thead from "@/Components/Table/Thead.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Table from "@/Components/Table/Table.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import {ref, watch} from "vue";
import Checkbox from "@/Components/Inputs/Checkbox.vue";

const props = defineProps({
    languages: Array,
    modelValue: Array,
})

const emit = defineEmits(['update:modelValue']);
const translationLanguages = ref(props.modelValue);

watch(props.modelValue, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(translationLanguages.value)) {
        translationLanguages.value = newVal;
    }
});

watch(translationLanguages, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(props.modelValue)) {
        emit('update:modelValue', newVal);
    }
});


Object.entries(props.languages).map(([index, newLang]) => {
    let foundInLanguages = false;

    translationLanguages.value.forEach((item, index) => {
        if (parseInt(item.language.ID) == parseInt(newLang.id) ) {
            foundInLanguages = true;
        }
    });
    if (!foundInLanguages) {
        translationLanguages.value.push(
            {
                ID: false,
                Language_ID: newLang.id,
                ToBeDeleted: true,
                Completed: null,
                user:{
                    ID: newLang.userID,
                    Name: newLang.userEmail,
                },
                language: {
                    ID: newLang.id,
                    Name: newLang.language,
                },
            }
        );
    }
});

</script>

<template>
    <div class="my-5">
        <Table>
            <Thead>
            <TheadTr>
                <TheadTh>Do kterých jazyků překládat?</TheadTh>
                <TheadTh>Email překladatele</TheadTh>
            </TheadTr>
            </Thead>
            <Tbody>
            <TbodyTr v-for="(lang, index) in translationLanguages" :key="index">
                <TbodyTd>
                    <Checkbox type="checkbox" :inverted="true" v-model="lang.ToBeDeleted" :invertedValue="true" :label="lang.language.Name" />
                </TbodyTd>
                <TbodyTd>
                    <NewSelect size="small"
                               v-model="lang.user"
                               v-if="!lang.ToBeDeleted"
                               :filterable="true"
                               :url="'/user/user-simple-list/data-list?Shop_ID=3'"
                               :remote-value-object="true"
                               :text-from-value="true"
                               label="Email překládajícího"
                    >
                    </NewSelect>
                </TbodyTd>
            </TbodyTr>
            </Tbody>
        </Table>
    </div>
</template>

<style scoped>

</style>
<script setup lang="ts">
    import Table from './Components/Table/Table.vue';
    import {type ITable} from "@project/Excel/Handsontable/js/Table.js";
    import {reactive} from "vue";
    import {SheetDefinitionColumn} from "@project/Excel/Handsontable/js/SheetDefinitionColumn";

    const props = defineProps<{
        table: any
    }>();

    const table = reactive<ITable>(props.table);

    const addColumn = () => {
        // const defColumn = new SheetDefinitionColumn({
        //     type: 'text',
        // });
        //
        // props.table.handsontableSettings.columns = [...props.table.handsontableSettings.columns as [], defColumn];
        table.addColumn(new SheetDefinitionColumn({
            title: '',
            type: 'text',
            defaultValue: '',
            model: '',
            data: '',
        }));
    }
</script>

<template>
    <Table :table="props.table" name="table"></Table>
<!--    <div class="h-[100%]">-->
<!--&lt;!&ndash;        EXCEL APP - ID[{{props.table.sheet.id}}] - {{props.table.sheet.name}}&ndash;&gt;-->
<!--    &lt;!&ndash;    <v-btn @click="addColumn" color="grey-lighten-2">Přidat sloupec</v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="h-[100%]">&ndash;&gt;-->
<!--&lt;!&ndash;            &ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        -->
<!--    </div>-->
</template>

<style scoped>

</style>
<script setup lang="ts">
//TODO
//dataJoin místo data i u joinů ze serveru

import {computed, reactive, ref, watch} from "vue";
import DisplaySheet from "@project/Excel/ExcelApp/Components/DisplaySheet/DisplaySheet.vue";
import SheetEditor from "@project/Excel/ExcelApp/Components/SheetEditor/SheetEditor.vue";
import {SheetManager} from "@project/Excel/Handsontable/js/SheetManager";
import { type ISheet, Sheet } from "@project/Excel/Handsontable/js/Sheet";
import Button from "@/Components/Inputs/Button.vue";
import Table from "@/Components/Table/Table.vue";
import Thead from "@/Components/Table/Thead.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import { cloneDeep } from "lodash";
import PriceListConfigurator from "@project/Excel/PriceList/Components/PriceListConfigurator.vue";
import Checkbox from "@/Components/Inputs/Checkbox.vue";
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import TextArea from "@/Components/Inputs/TextArea.vue";
import DatePicker from "@/Components/Inputs/DatePicker.vue";
import Layout from "./Components/Layout/Layout.vue";
import Pagination from "@/Components/Other/Pagination/Pagination.vue";
import Icon from "@/Icons/Icon.vue";
import InputField from "@/Components/Inputs/InputField.vue";
import {useAlertStore} from "@/Components/Alerts/stores/alertStore.js";
import { plainToInstance } from "class-transformer";
import {getActivePinia} from "pinia";
import {useSheetManager} from "@project/Excel/ExcelApp/Hooks/useSheetManager";
import { useRouter } from 'vue-router'
import SheetGrid from "@project/Excel/ExcelApp/Grids/SheetGrid.vue";

const create = ref<boolean>(false);

// Načítám settings => pushnu alert na načítání
const loadingAlert = ref<any>(null);

const props = defineProps<{
    sheet?: null|string
}>();

//alerts
const alerts = useAlertStore(window.pinia);
//end alerts

//router
const router = useRouter();
//end router

const {sheetManager}: SheetManager = useSheetManager();
sheetManager.load();

const deleteSheet = async (sheetID) => {
    const result = await sheetManager.deleteFromServer(sheetID);

    if(result){
        sheet_datagrid.refresh();
    }
}

const copySheet = async (sheetID) => {
    const result = await sheetManager.copySheetOnServer(sheetID);

    if(result){
        sheet_datagrid.refresh();
    }
}

//konfigurátor ceníku
const showPriceListConfigurator = ref<boolean>(false);

const onConfiguratorSheetCreated = async (sheet: ISheet) => {
    await onCreateFormSubmit(sheet);
    showPriceListConfigurator.value = false;
    return sheet;
}
//end konfigurátor ceníku

//handle pagination
const handlePageChange = (page: number) => {
    sheetManager.currentPage = page;
    sheetManager.load(page);
}
//end handle pagination

//sheet from grid
const sheet = ref(null);
if(props.sheet){
    sheet.value = plainToInstance(Sheet, JSON.parse(props.sheet));
    create.value = true;
}
//end sheet from grid


//labels
const labelsFilter = ref([]);

watch(labelsFilter, (newVal) => {
    if(newVal.length){
        sheet_datagrid.setUrlParameter('Label_IDs', newVal);
    }else{
        sheet_datagrid.unsetUrlParameter('Label_IDs');
    }

    sheet_datagrid.refresh();
});
//end labels

//search grid
const sheetGridSearch = ref('');
let sheetGridSearchTimeout = null;

watch(sheetGridSearch, (newVal) => {
    if (sheetGridSearchTimeout) {
        clearTimeout(sheetGridSearchTimeout);
    }

    sheetGridSearchTimeout = setTimeout(() => {
        if(newVal){
            sheet_datagrid.setUrlParameter('q', newVal);
        } else {
            sheet_datagrid.unsetUrlParameter('q');
        }

        sheet_datagrid.refresh();
        sheetGridSearchTimeout = null;
    }, 750);
});
//end serach grid
</script>

<template>
    <div class="excelApp">
        <div class="tw-mb-[-40px]">
            <Button @click="router.push({ name: 'labels' })" variant="default" size="small">Štítky</Button>
        </div>
        <div class="logo">
            <img src="/project/img/logo_remante.png">
        </div>
        <div class="title-bar">
            <div class="tw-flex tw-items-center">
                <div >
                    <h2 class="tw-w-[120px]">Ceníky a listy</h2>
                </div>

                <InputField
                    v-model="sheetGridSearch"
                    placeholder="Vyhledávání..."
                    icon="search"
                    class="tw-mr-4 tw-min-w-[300px]"
                    variant="fancy"
                ></InputField>
                <NewSelect
                    url="/admin/sheet-label/data-list"
                    :multiple="true"
                    placeholder="Filtr štítků..."
                    v-model="labelsFilter"
                    class="tw-min-w-[200px]"
                    :filterable="true"
                    size="small"
                >
                </NewSelect>
            </div>
            <div>
                <Button @click="router.push({ name: 'new-price-list' })" variant="green" icon="plus">Nový ceník</Button>
                <Button @click="router.push({ name: 'new-sheet' })" variant="blue" icon="plus">Nový list</Button>
            </div>
        </div>
<!--        <table class="mt-2" id="main-table-sheet-list">-->
<!--            <colgroup>-->
<!--                <col width="45">-->
<!--                <col width="200">-->
<!--                <col width="180">-->
<!--                <col width="100">-->
<!--                <col width="150">-->
<!--                <col width="180">-->
<!--                <col width="72">-->
<!--                <col width="200">-->
<!--            </colgroup>-->
<!--            <thead>-->
<!--            <tr>-->
<!--                <th>ID</th>-->
<!--                <th>Název</th>-->
<!--                <th>Umístění</th>-->
<!--                <th>Datum vytvoření</th>-->
<!--                <th>Poslední úprava</th>-->
<!--                <th>Autor</th>-->
<!--                <th>Naposledy jste otevřel</th>-->
<!--                <th>Akce</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody v-if="sheetManager.sheets.length">-->
<!--            <tr v-for="(sheet, index) in sheetManager.sheets" :key="index">-->
<!--                <td>{{sheet.id}}</td>-->
<!--                <td>-->
<!--                    <div class="tw-flex tw-items-center tw-gap-2">-->
<!--                        <Icon v-if="sheet.type === 'price-list'" icon="table" color="green"></Icon>-->
<!--                        <Icon v-else icon="list" color="blue"></Icon>-->
<!--                        <div @click="router.push({ name: 'display-sheet', params: { id: sheet.id } })" class="tw-text-[#4294FF] tw-font-medium hover:tw-cursor-pointer hover:tw-underline">{{sheet.name}}</div>-->
<!--                    </div>-->
<!--                </td>-->
<!--                <td><b>/velkoobchodní ceníky</b></td>-->
<!--                <td>{{ sheet.date.getUTCDate()+'.'+sheet.date.getUTCMonth()+'.'+sheet.date.getUTCFullYear() }}</td>-->
<!--                <td><b>18.05.2024 - 11:41</b></td>-->
<!--                <td>{{ sheet.UserName }}</td>-->
<!--                <td>17.04.2024</td>-->
<!--                <td>-->
<!--                    <Button @click="router.push({ name: 'display-sheet', params: { id: sheet.id } })" variant="default" icon="search" size="small" class="toolbar-button" tooltip="Zobrazí tabulku"></Button>-->
<!--                    <Button @click="" variant="default" icon="download" size="small" class="toolbar-button" tooltip="Zatím není implementováno"></Button>-->
<!--                    <Button v-if="sheet.type === 'price-list'" @click="router.push({ name: 'edit-price-list', params: { id: sheet.id } })" variant="default" icon="edit" size="small" class="toolbar-button" tooltip="Editovat"></Button>-->
<!--                    <Button v-else @click="router.push({ name: 'edit-sheet', params: { id: sheet.id } })" variant="default" icon="edit" size="small" class="toolbar-button" tooltip="Editovat"></Button>-->
<!--                    &lt;!&ndash;                                        <Button @click="copySheet(sheet)">K</Button>&ndash;&gt;-->
<!--                    <Button @click="" variant="default" icon="export" size="small" class="toolbar-button" tooltip="Zatím není implementováno"></Button>-->
<!--                    <Button @click="copySheet(sheet)" variant="default" icon="copy" size="small" class="toolbar-button" tooltip="Vytvořit kopii"></Button>-->
<!--                    <Button @click="deleteSheet(sheet)" variant="default" icon="delete" size="small" class="toolbar-button delete-button" tooltip="Smazat"></Button>-->
<!--                </td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--            <tfoot v-if="sheetManager.sheets.length">-->
<!--            <tr>-->
<!--                <td colspan="8" class="tw-pt-4">-->
<!--                    <Pagination :total-items="sheetManager.numRows" :items-per-page="sheetManager.rowsOnPage" @update:currentPage="handlePageChange" :current-page="sheetManager.currentPage" class="tw-justify-end"></Pagination>-->
<!--                </td>-->
<!--            </tr>-->
<!--            </tfoot>-->
<!--            <tfoot v-else>-->
<!--            <tr>-->
<!--                <td colspan="8">-->
<!--                    <p class="tw-pl-3">Žádný záznam</p>-->
<!--                </td>-->
<!--            </tr>-->
<!--            </tfoot>-->
<!--        </table>-->
        <SheetGrid
            @viewSheet="(item) => router.push({ name: 'display-sheet', params: { id: item.ID } })"
            @editSheet="(item) => {
                if(item.Type === 'price-list'){
                    router.push({ name: 'edit-price-list', params: { id: item.ID } })
                }else{
                    router.push({ name: 'edit-sheet', params: { id: item.ID } })
                }
            }"
            @copySheet="(item) => copySheet(item.ID)"
            @deleteSheet="(item) => deleteSheet(item.ID)"
        ></SheetGrid>
    </div>
</template>

<style scoped>
.excelApp{
    padding: 5px 0;
}

.logo{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 20px 0 50px 0;
}

.title-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

#main-table-sheet-list{
    width: 100%;
    border-collapse: collapse;

    thead{
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        color: #8898A7;
        border-bottom: 1px solid #ECF2FF;

        th{
            padding: 13px 15px;
            text-align: left;
        }
    }

    tbody{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        td{
            padding: 7px 15px;
            text-align: left;
        }
    }

    .toolbar-button{
        :deep(button:hover){
            background: #24C586;
            color: #FFF;

            .icon{
                color: #FFF !important;
            }
        }

        &.delete-button:deep(button:hover){
            background: #F00;
        }
    }
}
</style>
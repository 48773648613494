import {useModelDefinitionLoader} from "@project/Excel/ExcelApp/Hooks/useModelDefinitionLoader";
import {Sheet} from "@project/Excel/Handsontable/js/Sheet";
import {SheetSettings} from "@project/Excel/Handsontable/js/SheetSettings";
import {SheetDefinition} from "@project/Excel/Handsontable/js/SheetDefinition";
import {SheetDefinitionColumn} from "@project/Excel/Handsontable/js/SheetDefinitionColumn";
import { instanceToPlain } from "class-transformer";
import {Filter} from "../../../../../www/jolanda/front-vue/src/Filter/js/Filter";
import {FilterItem} from "../../../../../www/jolanda/front-vue/src/Filter/js/FilterItem";

export class GridToExcelMapper{
    public grid;
    public modelDefinitionLoader;

    constructor(grid) {
        this.grid = grid;

        const {loadModelDefinition} = useModelDefinitionLoader();
        this.modelDefinitionLoader = loadModelDefinition;
    }

    public async getExcelObject(){
        const gridOptions = this.grid.baseOptions;

        const sheet = new Sheet();
        sheet.settings = new SheetSettings();
        sheet.type = 'default';
        sheet.name = gridOptions.title ?? '';
        sheet.settings.name = gridOptions.title ?? '';

        const newModelDefinition = await this.createModelDefinition();

        if(!newModelDefinition){
            return false;
        }

        sheet.settings.modelDefinition = newModelDefinition;

        sheet.settings.sheetDefinition = this.createSheetDefinition(newModelDefinition);

        const data= JSON.stringify({
            ...instanceToPlain(sheet),
            SheetSettings: JSON.stringify(instanceToPlain(sheet.settings)),
        });

        return data;

        // /admin/product/data-list
    }

    public async createModelDefinition(){
        const gridOptions = this.grid.baseOptions;

        let managerID;
        try{
            managerID = this.transformPathToManager(gridOptions.ajax.url);

            if(!managerID){
                return false;
            }
        }catch (e){
            console.error(e);
            return false;
        }

        const newModelDefinition = await this.modelDefinitionLoader(managerID);

        if(!newModelDefinition){
            console.error(managerID + " model definition not found");
            return false;
        }

        const sort = this.grid.urlParameters.sort ?? null;

        if(sort){
            const dir = sort.startsWith('-') ? 'desc' : 'asc';
            const sortCol = sort.replace('-', '');

            newModelDefinition.sort = {
                column: sortCol,
                direction: dir,
            };
        }

        newModelDefinition.dataFilter = this.createFilter();
        newModelDefinition.dataUrl = this.grid.params.urls.datalistUrl;

        return newModelDefinition;
    }

    public createSheetDefinition(modelDefinition){
        const gridOptions = this.grid.baseOptions;

        const sheetDefinition = new SheetDefinition();

        let i = 0;
        gridOptions.aoColumnDefs.forEach((columnDef) => {
            if(columnDef.data === 'checkbox'){
                return;
            }

            if(!columnDef.mData && !columnDef.data){
                return;
            }

            const columnState = {
                data: columnDef.mData ?? columnDef.data,
                title: columnDef.label ?? columnDef.name ?? columnDef.mData ?? columnDef.data,
                type: 'text',
                joinData: columnDef.mData ?? columnDef.data,
                model: modelDefinition.id,
                position: i,
            };
            const column = new SheetDefinitionColumn(columnState);

            sheetDefinition.addColumn(column);

            i++;
        });

        return sheetDefinition;
    }

    public transformPathToManager(path) {
        // Rozdělit cestu podle '/'
        const parts = path.split('/');

        // Ujistit se, že cesta má alespoň 3 části
        if (parts.length < 3) {
            throw new Error("Invalid path format");
        }
        let index = 0;
        if(path.startsWith('/')){
            index = 1;
        }

        // Získat první dvě části, konvertovat je na velká písmena a první písmeno velké
        const firstPart = parts[index].charAt(0).toUpperCase() + parts[index].slice(1);
        index++;
        const secondPart = parts[index].charAt(0).toUpperCase() + parts[index].slice(1);

        // Sestavit výsledný řetězec
        return `${firstPart}_Manager_${secondPart}`;
    }

    public createFilter(){
        const filterState = {
            items: {},
        }

        Object.entries(this.grid.urlParameters).forEach(([key, value]) => {
           if(!value || (Array.isArray(value) && value.length === 0)){
               return;
           }

           if(key === 'start' || key === 'count' || key === 'sort'){
               return;
           }

           const itemState = {
               name: key,
                filters: [
                     {
                          operation: 'equal',
                          value: value,
                     }
                ]
           }
           const item = new FilterItem(itemState);
           filterState.items[key] = item;
        });

        const filter = new Filter(filterState);

        return filter;
    }
}
<script setup>

</script>

<template>
    <tr>
        <slot></slot>
    </tr>
</template>

<style scoped>

</style>
<script setup>

import {onMounted, ref} from "vue";
import axios from "axios";
import Grid from "@/DataGrid/Components/Grid.vue";

const emit = defineEmits(['editLabel', 'deleteLabel']);

const gridSetting = ref(null);

onMounted(() => {
    let url = window.remoteUrl + '/default/handsontable/labels-grid';
    axios.get(url, {

    }).then((response) => {
        gridSetting.value = response.data;
    });
});

window.editLabel = () => {
    emit('editLabel', labels_datagrid.getCurrentItem());
}

window.deleteLabel = () => {
    emit('deleteLabel', labels_datagrid.getCurrentItem());
}

</script>

<template>
    <div>
        <Grid v-if="gridSetting" :settings="gridSetting" :id="'labels_datagrid'" class="labelsDatagrid"></Grid>
    </div>
</template>

<style scoped>

</style>
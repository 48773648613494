<script setup lang="ts">
import {reactive, ref, watch} from 'vue';
import InputField from "@/Components/Inputs/InputField.vue";
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import Button from "@/Components/Inputs/Button.vue";
import axios from "axios";
import TextArea from "@/Components/Inputs/TextArea.vue";
import DatePicker from "@/Components/Inputs/DatePicker.vue";
import Supervisors from "./Edit/Supervisors.vue";
import Verificator from "./Edit/Verificator.vue";
import Groups from "./Edit/Groups.vue";
import Languages from "./Edit/Languages.vue";
import Attachments from "./Edit/Attachments.vue";
import AttachmentList from "@project/TranslationToolApp/Translate/AttachmentList.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import Thead from "@/Components/Table/Thead.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import Checkbox from "@/Components/Inputs/Checkbox.vue";
import Table from "@/Components/Table/Table.vue";


const props = defineProps<{
    languages: Array,
    canapprove: boolean,
    data: {
        ID: string,
        Title: string,
        Description: string,
        SourceLanguage_ID: string,
        Deadline: string,
        translationLanguages: Array<{
            Language_ID: string,
            Completed: string,
            language: Array<{
                ID: string,
                Name: string
            }>,
            user: Array<{
                ID: string,
                Name: string
            }>,
        }>,
        translationItemGroups: Array<{
            ID: string,
            Type: number,
            translationItems: Array<{
                ID: string,
                Item: string,
                Note: string,
                MinLenght: number,
                MaxLenght: number,
                Type: string
            }>,
        }>,
        translationSupervisors: Array<{
            ID: string,
            User_ID: number,
            user: Array<{
              ID: string,
              Name: string,
            }>,
        }>,
        translationVerificators: Array<{
            ID: string,
            User_ID: number,
            user: Array<{
              ID: string,
              Name: string,
            }>,
        }>,
        translationAttachments: Array<{
            ID: string,
            File_ID: number,
            file: Array<{
              ID: string,
              Type: string,
              Name: string,
              Extension: string,
            }>,
        }>,
        createdBy: {
            Name: string,
            Email: string
        }
    },
}>();

const state = reactive({
    ID: props.data.ID,
    Title: props.data.Title,
    Description: props.data.Description,
    SourceLanguage_ID: parseInt(props.data.SourceLanguage_ID),
    Deadline: props.data.Deadline,
    translationLanguages: props.data.translationLanguages ? props.data.translationLanguages.map(lang => ({
        ID: lang.ID,
        Language_ID: lang.Language_ID,
        Completed: lang.Compledted,
        ToBeDeleted: false,
        user:{
            ID: lang.user.ID,
            Name: lang.user.Name,
        },
        language: {
            ID: lang.language.ID,
            Name: lang.language.Name,
        },
    })) : [],
    translationItemGroups: props.data.translationLanguages ? props.data.translationItemGroups.map(group => ({
        ID: group.ID,
        Type: group.Type,
        translationItems: group.translationItems.map(item => ({
            ID: item.ID,
            Item: item.Item,
            Note: item.Note,
            ShowNote: item.Note != '' && item.Note != null,
            MinLenght: item.MinLenght,
            MaxLenght: item.MaxLenght,
            Type: item.Type,
            ToBeDeleted: false,
        })),
        ToBeDeleted: false,
    })) : [],
    translationSupervisors: props.data.translationSupervisors ? props.data.translationSupervisors.map(item => ({
        ID: item.ID,
        User_ID: item.User_ID,
        user: item.user,
        ToBeDeleted: false,
    })) : [],
    translationVerificators: props.data.translationVerificators ? props.data.translationVerificators.map(item => ({
        ID: item.ID,
        User_ID: item.User_ID,
        user: item.user,
        ToBeDeleted: false,
    })) : [],
    translationAttachments: props.data.translationAttachments ? props.data.translationAttachments.map(attachment => ({
        ID: attachment.ID,
        File_ID: attachment.File_ID,
        file: {
            ID: attachment.file.ID,
            Type: attachment.file.Type,
            Name: attachment.file.Name,
            Extension: attachment.file.Extension,
        },
        UploadedFile: false,
        UploadedFileName: false,
        ToBeDeleted: false,
    })) : [],
    createdBy: props.data.createdBy
});


const submitData = (approved = '') => {
    const formData = new FormData();
    formData.append('data', JSON.stringify(state));
    formData.append('approved', approved);

    // Přidání souborů
    state.translationAttachments.forEach((attachment, index) => {
        formData.append(`attachments[${index}]`, attachment.UploadedFile);
    });

    axios.post('/default/translation-tool/submit-new', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => {
        if (response.data.success) {
            window.location.href = '/default/translation-tool/list';
        } else {
            alert('Došlo k chybě při odesílání dat: ' + response.data.message);
        }
    })
    .catch(error => {
        console.error('Error submitting data:', error);
        alert('Nastala chyba při odesílání dat.');
    });
};

</script>

<template>
    <div>
        <template v-if="props.canapprove">

            <div class="translation-header-wrapper">
                <br>
                <h1>{{ state.Title }}</h1>
                <p>{{ state.Description}}</p>
                <br>
            </div>
            <br>

            <Table class="mb-5">
                <Thead>
                <TheadTr>
                    <TheadTh>Do kterých jazyků překládat?</TheadTh>
                    <TheadTh>Email překladatele</TheadTh>
                </TheadTr>
                </Thead>
                <Tbody>
                <TbodyTr v-for="(lang, index) in state.translationLanguages" :key="index">
                    <TbodyTd>
                        {{ lang.language.Name }}
                    </TbodyTd>
                    <TbodyTd>
                        {{ lang.user.Name }}
                    </TbodyTd>
                </TbodyTr>
                </Tbody>
            </Table>



            <div v-if="state.translationAttachments && state.translationAttachments.length">
                <AttachmentList ref="groupsRef" v-model="state.translationAttachments"></AttachmentList>
            </div>

        </template>
        <template v-else>

            <div class="translation-title-wrapper">
                <InputField size="small" v-model="state.Title" label="Nadpis překladů" />
            </div>
            <div class="translation-description-wrapper">
                <TextArea v-model="state.Description" label="Popisek o čem jsou překlady" class="mt-1"></TextArea>
            </div>
            <div class="translation-source-language-wrapper">
                <NewSelect v-model="state.SourceLanguage_ID"
                           label="Zdrojový jazyk" class="mt-1"
                           :options="Object.values(props.languages || {}).map(lang => ({ text: lang.language, value: lang.id }))"
                           size="small"
                >
                </NewSelect>
            </div>
            <div class="translation-deadline-wrapper">
                <DatePicker size="small" v-model="state.Deadline" label="Deadline" class="mt-1" />
            </div>

            <div class="translation-languages-wrapper">
                <Languages v-model="state.translationLanguages" :languages="props.languages"></Languages>
            </div>
            <div class="translation-supervisors-wrapper">
                <Supervisors v-model="state.translationSupervisors"></Supervisors>
            </div>
            <div class="translation-verificators-wrapper">
                <Verificator v-model="state.translationVerificators"></Verificator>
            </div>
            <div class="translation-attachments-wrapper">
                <Attachments v-model="state.translationAttachments"></Attachments>
            </div>

        </template>

            <Groups v-model="state.translationItemGroups"></Groups>

        <div class="text-right mb-5">
            <Button v-if="props.canapprove" size="small" @click="submitData('1')" variant="yellow" class="mt-5">Aktualizovat a schválit texty</Button>
            <Button size="small" @click="submitData" variant="red" class="mt-5 translation-submit-data">
                <span v-if="state.ID">Aktualizovat překlady</span>
                <span v-else>Uložit</span>
            </Button>
        </div>
    </div>
</template>

<script setup>
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import Thead from "@/Components/Table/Thead.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Table from "@/Components/Table/Table.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import Button from "@/Components/Inputs/Button.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import {ref, watch} from "vue";

const props = defineProps({
    modelValue: Array,
})

const emit = defineEmits(['update:modelValue']);
const translationAttachments = ref(props.modelValue);

watch(props.modelValue, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(translationAttachments.value)) {
        translationAttachments.value = newVal;
    }
});

watch(translationAttachments, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(props.modelValue)) {
        emit('update:modelValue', newVal);
    }
});


const addAttachment = () => {
    translationAttachments.value.push({
        ID: false,
        File_ID: null,
        file: {
            ID: null,
            Type: null,
            Name: null,
            Extension: null,
        },
        UploadedFile: false,
        UploadedFileName: false,
        ToBeDeleted: false,
    });
};

const removeAttachment = (index) => {
    if (translationAttachments.value[index].ID) {
        translationAttachments.value[index].ToBeDeleted = true;
    } else {
        translationAttachments.value.splice(index, 1);
    }
};

const handleFileChange = (event, index) => {
    const file = event.target.files[0]; // Získání souboru z input elementu
    translationAttachments.value[index].UploadedFile = file; // Uložení souboru do stavu
    translationAttachments.value[index].UploadedFileName = file.name;
};


</script>

<template>
    <div class="my-5">
        <Table>
            <Thead>
            <TheadTr>
                <TheadTh>Souborové přílohy</TheadTh>
                <TheadTh>Název souboru</TheadTh>
                <TheadTh>Akce</TheadTh>
            </TheadTr>
            </Thead>
            <Tbody>
            <TbodyTr v-for="(attachment, index) in translationAttachments" :key="index">
                <template v-if="!attachment.ToBeDeleted">
                    <TbodyTd>
                        <input type="file" @change="handleFileChange($event, index)" />
                    </TbodyTd>
                    <TbodyTd>
                        <div v-if="attachment.UploadedFileName">
                            <span class="badge badge-primary">NOVÝ</span> {{ attachment.UploadedFileName }}
                        </div>
                        <div v-else>
                            {{ attachment.file.Name }}.{{ attachment.file.Extension }}
                        </div>
                    </TbodyTd>
                    <TbodyTd>
                        <Button size="small" @click="removeAttachment(index)" variant="red">Odebrat</Button>
                    </TbodyTd>
                </template>
            </TbodyTr>
            <TbodyTr>
                <td :colspan="3">
                    <Button size="small" @click="addAttachment" variant="green" class="m-3">Přidat přílohu</Button>
                </td>
            </TbodyTr>
            </Tbody>
        </Table>
    </div>
</template>

<style scoped>

</style>
<script setup>

import Modal from "@/Components/Modal/Modal.vue";
import Button from "@/Components/Inputs/Button.vue";
import { reactive, ref, watch } from "vue";

const props = defineProps({
    show: Boolean,
    table: Object,
    data: [],
});

const emit = defineEmits(['close', 'save']);

const show = ref(props.show);

const save = () => {
    props.table.setData(props.data);

    emit('save');
    close();
}

const close = () => {
    show.value = false;
    emit('close');
}

watch(() => props.show, (val) => {
    show.value = props.show;
});
</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close">
        <template #header>
            Velké množství dat
        </template>
        <template #body>
            <p>Načítáte velké množství dat ({{data.length}} řádků). Opravdu chcete pokračovat?</p>
            <p><i>Upozornění: Zpracování velkého objemu dat může zpomalit aplikaci.</i></p>
        </template>
        <template #footer>
            <Button variant="default" @click="save">Načíst</Button>
            <Button variant="red" @click="close">Nenačítat</Button>
        </template>
    </Modal>
</template>

<style scoped>

</style>
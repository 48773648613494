<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(24,24));

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 24 24" fill="none" :style="{color: color}">
        <path d="M11.034 20.751C10.711 20.751 10.388 20.66 10.104 20.48C9.61499 20.171 9.32199 19.647 9.32199 19.078V12.562L3.55499 5.856C3.52499 5.821 3.49799 5.784 3.47499 5.745C3.17599 5.232 3.17399 4.619 3.46999 4.104C3.77299 3.577 4.34599 3.25 4.96499 3.25H19.035C19.654 3.25 20.227 3.577 20.53 4.104C20.826 4.619 20.824 5.232 20.525 5.745C20.502 5.784 20.475 5.821 20.445 5.856L14.677 12.562V18.158C14.677 18.8 14.31 19.374 13.718 19.658L11.79 20.578C11.549 20.693 11.29 20.751 11.033 20.751H11.034ZM4.75399 4.951L10.641 11.795C10.758 11.932 10.823 12.105 10.823 12.284V19.078C10.823 19.133 10.85 19.178 10.905 19.212C10.94 19.234 11.032 19.278 11.145 19.225L13.072 18.305C13.137 18.274 13.178 18.217 13.178 18.159V12.285C13.178 12.105 13.242 11.932 13.36 11.796L19.247 4.952C19.253 4.927 19.254 4.892 19.23 4.852C19.212 4.822 19.159 4.75 19.036 4.75H4.96499C4.84199 4.75 4.78799 4.821 4.77099 4.852C4.74799 4.892 4.74899 4.925 4.75399 4.951Z" fill="currentColor"/>
    </svg>
</template>

<style scoped>

</style>
<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(15,15));

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 15 15" fill="none" :style="{color: color}">
        <path d="M8.69099 7.5001L13.7529 2.4379C14.0824 2.10862 14.0824 1.57623 13.7529 1.24696C13.4237 0.917681 12.8913 0.917681 12.562 1.24696L7.49992 6.30915L2.43798 1.24696C2.10856 0.917681 1.57633 0.917681 1.24707 1.24696C0.917644 1.57623 0.917644 2.10862 1.24707 2.4379L6.30901 7.5001L1.24707 12.5623C0.917644 12.8916 0.917644 13.424 1.24707 13.7532C1.41116 13.9175 1.62692 14 1.84253 14C2.05813 14 2.27374 13.9175 2.43798 13.7532L7.49992 8.69104L12.562 13.7532C12.7263 13.9175 12.9419 14 13.1575 14C13.3731 14 13.5887 13.9175 13.7529 13.7532C14.0824 13.424 14.0824 12.8916 13.7529 12.5623L8.69099 7.5001Z" fill="currentColor"/>
    </svg>
</template>

<style scoped>

</style>
<script setup>
import {ref, onMounted, watch, nextTick, onUnmounted} from 'vue';
import Icon from '@/Icons/Icon.vue';
import {FORMULAS} from '@project/Excel/Handsontable/js/Formulas/formulas.js';
import HighlightText from '@/Components/Other/HighlightText.vue';
import Tooltip from '@/Components/Other/Tooltip.vue';

const props = defineProps({
    dataTable: Object,
});

const userInput = ref('');
const functionsList = ref([]);
const filteredFunctions = ref([]);
const selectedIndex = ref(0);
const showSuggestions = ref(false);
const inputField = ref(null);
const isSpecialSuggestions = ref(false);
const cell = ref(null);
const highlight = ref('');

const handleClickOutside = (event) => {
    if (inputField.value && !inputField.value.contains(event.target) && !document.querySelector('.suggestions-list')?.contains(event.target)) {
        showSuggestions.value = false;
    }
};

const onEnterPress = () => {
    submitInput();
};

const onEscapePress = () => {
    clearInput();
};

const onKeydown = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault(); // Prevent form submission
        onEnterPress(); // Call submit input
    } else if (event.key === 'Escape') {
        event.preventDefault();
        onEscapePress(); // Call clear input
    }
};

const loadFunctions = () => {
    // Sample data
    const jsonData = FORMULAS;

    functionsList.value = Object.keys(jsonData).reduce((acc, category) => {
        const functions = Object.keys(jsonData[category]).map(name => ({
            name,
            syntax: jsonData[category][name].syntax,
            description: jsonData[category][name].description
        }));
        return acc.concat(functions);
    }, []).sort((a, b) => a.name.localeCompare(b.name)); // Seřazení podle názvu
};


const loadSpecialSuggestions = () => {
    const columns = props.dataTable.sheet.settings.sheetDefinition.columns;

    return columns.map(column => {
        const hasAlias = column.alias && column.alias.trim().length > 0;
        return {
            name: hasAlias ? column.alias : column.joinData,
            syntax: hasAlias ? column.alias : column.joinData,
            description: column.joinData,
        };
    });
};

const onInput = () => {
    highlight.value = '';
    const inputText = userInput.value;

    // Zkontroluj, jestli input začíná znakem "="
    if (!inputText.startsWith('=')) {
        showSuggestions.value = false;
        isSpecialSuggestions.value = false; // Vypneme special suggestions, pokud nejsou potřeba
        return; // Přeruš funkci, pokud input nezačíná "="
    }

    const cursorPosition = inputField.value.selectionStart; // Pozice kurzoru

    // Najdeme poslední "slovo", které uživatel píše před kurzorem
    const beforeCursor = inputText.slice(0, cursorPosition);
    const afterCursor = inputText.slice(cursorPosition);

    // Kontrola, jestli píšeme uvnitř COLUMN()
    const columnMatch = beforeCursor.match(/COLUMN\(([^)]*)$/i); // Najdi část COLUMN(

    if (columnMatch) {
        // Pokud je uživatel uvnitř COLUMN(), použijeme vlastní seznam suggestionů
        const specialSuggestions = loadSpecialSuggestions();
        filteredFunctions.value = specialSuggestions.filter(func =>
            func.name.toUpperCase().includes(columnMatch[1].toUpperCase())
        );
        highlight.value = columnMatch[1].toUpperCase();
        showSuggestions.value = filteredFunctions.value.length > 0;
        isSpecialSuggestions.value = true; // Nastavíme, že jsou aktivní special suggestions
        selectedIndex.value = 0;
    } else {
        // Najdeme aktuální slovo
        const match = beforeCursor.match(/(\w+)$/);
        const currentWord = match ? match[0].toUpperCase() : ''; // Převést na velká písmena

        if (currentWord.length > 0) {
            // Filtrovat suggestion pouze pro aktuální slovo
            filteredFunctions.value = functionsList.value.filter(func =>
                func.name.includes(currentWord)
            );
            highlight.value= currentWord;
            showSuggestions.value = filteredFunctions.value.length > 0;
            isSpecialSuggestions.value = false; // Nastavíme, že jsou aktivní normální suggestions
            selectedIndex.value = 0;
        } else {
            showSuggestions.value = false;
            isSpecialSuggestions.value = false; // Žádné suggestions
        }
    }
};

const clearInput = () => {
    if(cell.value){
        const data = props.dataTable.handsontable.getSourceDataAtCell(cell.value[0], props.dataTable.handsontable.toVisualColumn(cell.value[1]));
        if(props.dataTable.isFormula(data)){
            const cellMeta = props.dataTable.handsontable.getCellMeta(props.dataTable.handsontable.toVisualRow(cell.value[0]), props.dataTable.handsontable.toVisualColumn(cell.value[1]));

            if(typeof cellMeta.origFormula !== 'undefined' && cellMeta.origFormula && props.dataTable.isFormula(cellMeta.origFormula)){
                userInput.value = cellMeta.origFormula;
            }
        }else{
            userInput.value = props.dataTable.handsontable.getSourceDataAtCell(cell.value[0], props.dataTable.handsontable.toVisualColumn(cell.value[1]));
        }
    }else{
        userInput.value = '';
    }
    showSuggestions.value = false;
};

const submitInput = () => {
    if(cell.value){
        props.dataTable.handsontable.setDataAtCell(props.dataTable.handsontable.toVisualRow(cell.value[0]), props.dataTable.handsontable.toVisualColumn(cell.value[1]), userInput.value);
    }
};

const submitInputColumn = () => {
    if(cell.value){
        if (confirm("Opravdu chcete aplikovat hodnotu nad všemi řádky?")) {
            const col = props.dataTable.sheet.settings.sheetDefinition.columns[cell.value[1]];

            const data = props.dataTable.sheet.data.map((row) => {
                row[col.data ? col.joinData : col.alias] = userInput.value;
                return row;
            });

            props.dataTable.updateData(data);
        } else {
        }
    }
}

const submitInputFilterColumn = () => {
    if(cell.value){
        const col = props.dataTable.sheet.settings.sheetDefinition.columns[cell.value[1]];
        //
        // const data = props.dataTable.sheet.data.map((row) => {
        //     row[col.data ? col.joinData : col.alias] = userInput.value;
        //     return row;
        // });
        //
        // // const oldData = [...props.dataTable.sheet.data];
        // props.dataTable.updateData(data);


        const visualColumnIndex = props.dataTable.handsontable.toVisualColumn(cell.value[1]); // Fyzický index sloupce
        const totalVisualRows = props.dataTable.handsontable.countRows(); // Získáš počet fyzických řádků

        props.dataTable.handsontable.batch(() => {
            for (let visualRowIndex = 0; visualRowIndex < totalVisualRows; visualRowIndex++) {
                // Používáme fyzické indexy řádku a sloupce pro nastavení hodnoty do zdrojových dat
                props.dataTable.handsontable.setDataAtCell(visualRowIndex, visualColumnIndex, userInput.value);
            }
        });

    }
}

const onArrowDown = () => {
    if (selectedIndex.value < filteredFunctions.value.length - 1) {
        selectedIndex.value++;
        scrollToActiveSuggestion();
    }
};

const onArrowUp = () => {
    if (selectedIndex.value > 0) {
        selectedIndex.value--;
        scrollToActiveSuggestion();
    }
};

const scrollToActiveSuggestion = () => {
    nextTick(() => {
        const activeItem = document.querySelector('.suggestions-list li.is-active');
        if (activeItem) {
            activeItem.scrollIntoView({
                block: 'nearest',
                inline: 'nearest',
                behavior: 'smooth'
            });
        }
    });
};


const onTab = () => {
    const selectedFunction = filteredFunctions.value[selectedIndex.value];
    if (selectedFunction) {
        const cursorPosition = inputField.value.selectionStart;
        const inputText = userInput.value;

        // Najdeme aktuální slovo, které nahrazujeme
        const beforeCursor = inputText.slice(0, cursorPosition);
        const afterCursor = inputText.slice(cursorPosition);
        const match = beforeCursor.match(/(\w*)$/); // Modifikováno: (\w*) zachytí i prázdné slovo

        const currentWord = match ? match[0] : '';

        // Pokud je funkce COLUMN() a uvnitř není žádný text, nenahrazuj aktuální slovo
        if (isSpecialSuggestions.value && currentWord === '') {
            userInput.value = beforeCursor + selectedFunction.syntax + afterCursor;
        } else {
            userInput.value = beforeCursor.slice(0, -currentWord.length) + selectedFunction.syntax + afterCursor;
        }

        // Nastavíme pozici kurzoru za vložený suggestion
        const newCursorPosition = beforeCursor.length - currentWord.length + selectedFunction.syntax.length;
        nextTick(() => {
            inputField.value.setSelectionRange(newCursorPosition, newCursorPosition);
        });

        showSuggestions.value = false;
    }
};

const onSelect = (func) => {
    const cursorPosition = inputField.value.selectionStart;
    const inputText = userInput.value;

    // Najdeme aktuální slovo, které nahrazujeme
    const beforeCursor = inputText.slice(0, cursorPosition);
    const afterCursor = inputText.slice(cursorPosition);
    const match = beforeCursor.match(/(\w*)$/); // Modifikováno: (\w*) zachytí i prázdné slovo

    const currentWord = match ? match[0] : '';

    // Pokud je funkce COLUMN() a uvnitř není žádný text, nenahrazuj aktuální slovo
    if (isSpecialSuggestions.value && currentWord === '') {
        userInput.value = beforeCursor + func.syntax + afterCursor;
    } else {
        userInput.value = beforeCursor.slice(0, -currentWord.length) + func.syntax + afterCursor;
    }

    // Nastavíme pozici kurzoru za vložený suggestion
    const newCursorPosition = beforeCursor.length - currentWord.length + func.syntax.length;
    nextTick(() => {
        inputField.value.setSelectionRange(newCursorPosition, newCursorPosition);
    });

    showSuggestions.value = false;
    inputField.value.focus();
};

const tableOneCellSelected = () => {
    const lastSelectedCells = props.dataTable.lastSelectedCells ?? null;
    if(!lastSelectedCells || lastSelectedCells.length !== 1){
        return false;
    }

    if(lastSelectedCells[0][0] === lastSelectedCells[0][2] && lastSelectedCells[0][1] === lastSelectedCells[0][3]){
        if(lastSelectedCells[0][0] >= 0 && lastSelectedCells[0][1] >= 0){
            return [props.dataTable.handsontable.toPhysicalRow(lastSelectedCells[0][0]),  props.dataTable.handsontable.toPhysicalColumn(lastSelectedCells[0][1])];
        }

        return false;
    }

    return false;
}

watch(() => props.dataTable.lastSelectedCells, (newVal) => {
    const selectedCell = tableOneCellSelected();
    if(props.dataTable.handsontable){
        props.dataTable.handsontable.getPlugin('customBorders').clearBorders();
    }

    if(selectedCell){
        const data = props.dataTable.handsontable.getSourceDataAtCell(selectedCell[0], props.dataTable.handsontable.toVisualColumn(selectedCell[1]));
        if(props.dataTable.isFormula(data)){
            const cellMeta = props.dataTable.handsontable.getCellMeta(props.dataTable.handsontable.toVisualRow(selectedCell[0]), props.dataTable.handsontable.toVisualColumn(selectedCell[1]));

            if(typeof cellMeta.origFormula !== 'undefined' && cellMeta.origFormula && props.dataTable.isFormula(cellMeta.origFormula)){
                userInput.value = cellMeta.origFormula;
            }
        }else{
            userInput.value = props.dataTable.handsontable.getSourceDataAtCell(selectedCell[0], props.dataTable.handsontable.toVisualColumn(selectedCell[1]));
        }
        cell.value = [
            selectedCell[0],
            selectedCell[1],
        ];

        const cellRange = [props.dataTable.handsontable.toVisualRow(selectedCell[0]), props.dataTable.handsontable.toVisualColumn(selectedCell[1]), props.dataTable.handsontable.toVisualRow(selectedCell[0]), props.dataTable.handsontable.toVisualColumn(selectedCell[1])];

        const borderObj = {
            bottom: {width: 2, color: 'rgba(75, 137, 255,0.44)'},
            top: {width: 2, color: 'rgba(75, 137, 255,0.44)'},
            left: {width: 2, color: 'rgba(75, 137, 255,0.44)'},
            right: {width: 2, color: 'rgba(75, 137, 255,0.44)'},
        };
        props.dataTable.handsontable.getPlugin('customBorders').setBorders([cellRange, cellRange], borderObj);
    }else{
        cell.value = null;
        userInput.value = '';
    }


}, {deep: true, immediate: true});

onMounted(() => {
    loadFunctions();

    document.addEventListener('mousedown', handleClickOutside);
});

onUnmounted(() => {
    document.removeEventListener('mousedown', handleClickOutside);
});

</script>

<template>
    <div class="formula-input-container"
         :class="{ 'disabled': !cell }"
    >
        <div class="input-icons">
            <Tooltip text="Zrušit">
                <Icon icon="close" color="red" @click="clearInput" class="tw-mr-2"
                    :class="{
                        'hover:tw-cursor-pointer': cell,
                        'hover:tw-opacity-70': cell,
                    }"
                ></Icon>
            </Tooltip>
            <Tooltip text="Použít pro buňku">
                <Icon icon="success" color="green" @click="submitInput" class="tw-mr-2"
                    :class="{
                        'hover:tw-cursor-pointer': cell,
                        'hover:tw-opacity-70': cell,
                    }"
                ></Icon>
            </Tooltip>
            <Tooltip text="Použít pro sloupec">
                <Icon icon="rowHeight" color="green" @click="submitInputColumn" class="tw-mr-3"
                    :class="{
                        'hover:tw-cursor-pointer': cell,
                        'hover:tw-opacity-70': cell,
                    }"
                ></Icon>
            </Tooltip>
            <Tooltip text="Použít pro vyfiltrovaný sloupec">
                <Icon icon="rowHeightFilter" color="green" @click="submitInputFilterColumn" class="tw-mr-3"
                      :class="{
                        'hover:tw-cursor-pointer': cell,
                        'hover:tw-opacity-70': cell,
                    }"
                ></Icon>
            </Tooltip>
        </div>
        <div class="tw-relative input-wrapper">
            <Icon icon="fx" color="#888" @click="submitInputColumn" class="tw-absolute fx-icon"></Icon>
            <input
                v-model="userInput"
                @input="onInput"
                @keydown.down.prevent="onArrowDown"
                @keydown.up.prevent="onArrowUp"
                @keydown.tab.prevent="onTab"
                @keydown="onKeydown"
                ref="inputField"
                placeholder="Enter formula..."
                class="formula-input"
                :disabled="!cell"
                :class="{ 'disabled-input': !cell }"
            />
        </div>
        <ul v-if="filteredFunctions.length && showSuggestions" class="suggestions-list">
            <li
                v-for="(func, index) in filteredFunctions"
                :key="index"
                :class="{ 'is-active': index === selectedIndex }"
                @mousedown="onSelect(func)"
                @mouseover="selectedIndex = index"
            >
                <template v-if="!isSpecialSuggestions">
                    <strong class="name"><HighlightText :text="func.name" :highlight="highlight"></HighlightText></strong> - <span class="syntax">{{ func.syntax }}</span> - <span class="description">[{{func.description}}]</span>
                </template>
                <template v-else>
                    <strong class="name"><HighlightText :text="func.name" :highlight="highlight"></HighlightText></strong> - <span class="description">[{{func.description}}]</span>
                </template>
            </li>
        </ul>
    </div>
</template>


<style scoped>
.formula-input-container {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0 0px 0px 10px;
}

.input-wrapper{
    width: 100%;

    .fx-icon{
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
    }

    input{
        padding-left: 30px;
        width: 100%;
        border-radius: 20px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.input-icons {
    display: flex;
    align-items: center;
}

.icon-close, .icon-check, .icon-fx {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    cursor: pointer;
    color: black; /* Icons will be black */
}

.icon-fx {
    font-family: "Arial", sans-serif; /* Add custom style for fx icon if needed */
}

.formula-input {
    flex-grow: 1;
    padding: 6px;
    border: none;
    outline: none;
    font-size: 14px;
}

.disabled-input {
    background-color: #dcdcdc;
    color: #888;
    cursor: not-allowed;
}

.disabled{
    color: #888;
    cursor: not-allowed;
}

.suggestions-list {
    z-index: 999;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 150px;
    overflow-y: auto; /* Přidáno pro umožnění vertikálního scrollování */
    border: 1px solid #ccc;
    background: white;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
}

.suggestions-list li {
    display: flex;
    font-size: 12px;
    padding: 8px;
    cursor: pointer;

    .description{
        opacity: 0.75;
        margin-left: 8px;
        margin-right: 8px;
    }

    .syntax{
        margin-left: 8px;
        margin-right: 8px;
    }

    .name{
        margin-right: 8px;
    }
}

.suggestions-list li.is-active {
    background-color: #007bff;
    color: white;
}

.suggestions-list li:hover {
    background-color: #007bff;
    color: white;
}
</style>

<script setup>
    import {IconProps, useIcon} from './useIcon.js'

    const props = defineProps(IconProps(18,18));

    const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 18 18" fill="none" :style="{color: color}">
        <path d="M2.35419 8.81762C2.35419 5.25339 5.25414 2.35419 8.81845 2.35419C12.3819 2.35419 15.2819 5.25338 15.2819 8.81762C15.2819 12.3819 12.3819 15.2819 8.81845 15.2819C5.25417 15.2819 2.35419 12.3819 2.35419 8.81762ZM0.85 8.81762C0.85 13.2115 4.42457 16.786 8.81845 16.786C13.2123 16.786 16.7861 13.2115 16.7861 8.81762C16.7861 4.42377 13.2123 0.85 8.81845 0.85C4.42459 0.85 0.85 4.42375 0.85 8.81762Z" fill="currentColor" stroke="currentColor" stroke-width="0.3"/>
        <path d="M16.1347 17.5657L16.1349 17.5659C16.282 17.712 16.4746 17.7853 16.6661 17.7853C16.8579 17.7853 17.0519 17.7123 17.1988 17.5636C17.4925 17.2695 17.4911 16.7942 17.1976 16.5008L17.1975 16.5007L14.3685 13.6789L14.3685 13.6789L14.368 13.6785C14.0742 13.3876 13.5979 13.3844 13.3041 13.6812L13.4108 13.7867L13.3047 13.6806C13.0106 13.9747 13.012 14.451 13.3055 14.7445L13.3056 14.7447L16.1347 17.5657Z" fill="currentColor" stroke="currentColor" stroke-width="0.3"/>
    </svg>
</template>

<style scoped>

</style>
<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(24,24));

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 24 24" fill="none" :style="{color: color}">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.57773 5.22644C5.70844 5.18921 5.82171 5.10593 5.89453 4.99117C7.03476 3.19446 9.04278 2 11.3308 2C14.2623 2 16.7351 3.95988 17.5117 6.64105C17.5694 6.84037 17.7353 6.99025 17.9377 7.03615C18.6317 7.19354 19.2565 7.53293 19.7594 8.0015C20.0903 8.30977 19.7459 8.79407 19.2937 8.78832C19.2754 8.78808 19.257 8.78796 19.2385 8.78796H12.3055C9.95833 8.78796 8.05554 10.6908 8.05554 13.038V13.9788C8.05554 14.0723 8.05856 14.1651 8.06451 14.2571C8.08503 14.5745 7.84917 14.8685 7.5311 14.8685H6.9264C4.21203 14.8685 2.0116 12.6681 2.0116 9.9537C2.0116 7.70666 3.51895 5.81285 5.57773 5.22644Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.55554 13.0381C9.55554 11.5193 10.7868 10.2881 12.3055 10.2881H19.2385C20.7573 10.2881 21.9885 11.5193 21.9885 13.0381C21.9885 14.5568 20.7573 15.7881 19.2385 15.7881H12.3055C10.7868 15.7881 9.55554 14.5568 9.55554 13.0381ZM16.9628 12.2881C16.5486 12.2881 16.2128 12.6239 16.2128 13.0381C16.2128 13.4523 16.5486 13.7881 16.9628 13.7881H19.0557C19.4699 13.7881 19.8057 13.4523 19.8057 13.0381C19.8057 12.6239 19.4699 12.2881 19.0557 12.2881H16.9628Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.55554 19.25C9.55554 17.7312 10.7868 16.5 12.3055 16.5H19.2385C20.7573 16.5 21.9885 17.7312 21.9885 19.25C21.9885 20.7688 20.7573 22 19.2385 22H12.3055C10.7868 22 9.55554 20.7688 9.55554 19.25ZM17.3104 18.5C16.8962 18.5 16.5604 18.8358 16.5604 19.25C16.5604 19.6642 16.8962 20 17.3104 20H19.4033C19.8175 20 20.1533 19.6642 20.1533 19.25C20.1533 18.8358 19.8175 18.5 19.4033 18.5H17.3104Z" fill="currentColor"/>
    </svg>
</template>

<style scoped>

</style>
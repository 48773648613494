<script setup lang="ts">
    import {reactive, ref, watch} from "vue";
    import {cloneDeep} from "lodash";
    import {useUpdateReactiveObject} from "@project/Excel/Hooks/useUpdateReactiveObject.js";
    import {Sheet} from "@project/Excel/Handsontable/js/Sheet";
    import {type IModelDefinition, ModelDefinition, type ModelDefinitionState} from "@project/Excel/Handsontable/js/ModelDefinition";
    import {type ISheetDefinition, SheetDefinition} from "@project/Excel/Handsontable/js/SheetDefinition";
    import Button from "@/Components/Inputs/Button.vue";
    import SheetDefinitionColumnsEditor from "@project/Excel/ExcelApp/Components/SheetEditor/SheetDefinitionColumnsEditor.vue";
    import ModelEditorDialog from "@project/Excel/ExcelApp/Components/SheetEditor/ModelEditorDialog.vue";
    import Icon from "@/Icons/Icon.vue";
    import type {SheetSettings} from "@project/Excel/Handsontable/js/SheetSettings";

    const emit = defineEmits(['change']);

    const props = defineProps<{
        sheetSettings: SheetSettings|null
    }>();

    const { updateObject } = useUpdateReactiveObject();

    const modelDefinition: IModelDefinition = reactive(props.sheetSettings?.modelDefinition ? new ModelDefinition(cloneDeep(props.sheetSettings.modelDefinition)) : new ModelDefinition(null));
    const sheetDefinition: ISheetDefinition = reactive(props.sheetSettings?.sheetDefinition ? new SheetDefinition(cloneDeep(props.sheetSettings.sheetDefinition)) : new SheetDefinition(null));

    const saveModelDefinition = (newModelDefinition: IModelDefinition) => {
        updateObject(modelDefinition, newModelDefinition);
    };

    watch([modelDefinition, sheetDefinition], ([newModelDefinition, newsSheetDefinition]) => {
        emit('change', newModelDefinition, newsSheetDefinition);
    });

    //MODEL DIALOG
    const showModelDialog = ref(false);
    //END MODEL DIALOG
</script>

<template>
    <div class="">
        <SheetDefinitionColumnsEditor :sheet-definition="sheetDefinition" :model-definition="modelDefinition"></SheetDefinitionColumnsEditor>
        <div class="sheet-definition-editor-wrapper">
            <div class="definition-panel-wrapper">
                <div class="definition-panel">
                    <Icon icon="cloud" color="blue"></Icon>
                    <h2>Definice data modelu</h2>
                    <div class="button-wrapper">
                        <div v-if="modelDefinition.id === ''">
                            <Button @click="showModelDialog = true;" variant="blue" icon="plus">Vytvořit model</Button>
                        </div>
                       <div v-else>
                           <div class="model-name">
                              {{ modelDefinition.name }}
                           </div>
                           <Button @click="showModelDialog = true;" variant="blue" icon="edit">Editovat model</Button>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <ModelEditorDialog v-if="showModelDialog" :show="showModelDialog" :model-definition="modelDefinition" @save="saveModelDefinition" @close="showModelDialog = false"></ModelEditorDialog>

    </div>
</template>

<style scoped>
    .sheet-definition-editor-wrapper{
        margin-top: 42px;
        text-align: center;

        .definition-panel-wrapper{
            display: inline-block;
        }

        .definition-panel{
            padding: 28px 20px;
            display: flex;
            border-radius: 10px;
            align-items: center;
            box-shadow: 0px 4px 44px 0px rgba(9, 19, 37, 0.10);

            h2{
                margin-left: 10px;
                margin-right: 13px;
            }

            .button-wrapper{
                display: flex;

                & > div{
                    display: flex;
                    align-items: center;
                }
            }

            .model-name{
                margin-right: 90px;
                font-size: 16px;
                font-weight: 600;
                color: #8898A7;
            }
        }
    }
</style>